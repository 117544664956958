import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { notification, message as MESSAGE } from "antd";
export const configJSON = require("./config.js");
import * as H from "history"
import React from "react";
import { match } from 'react-router-dom';
import { FormType } from "../../customform3/src/Customform3Controller.web";

export interface SubMenuType {
  id: string
  type: "category"
  attributes: {
    id: number
    name: string
    arabic_name: string
    icon_url: string
    category_type: string
    section: string
  }
}

export type CategoryKeys = keyof SubMenuType['attributes']

// Customizable Area End

export type Props = RouterProps &
// Customizable Area Start
{
  history: H.History;
  match: match;
  location: H.Location;
  // Customizable Area End
};

export interface S {
  // Customizable Area Start 
  switchLanguage: boolean;
  openPopover: { [key: string]: null | Element }
  selectedMore: null | string
  openFormDialog: FormType | null
  categories: {[key:string]: Array<SubMenuType> | undefined}
  submitDialog: FormType | null;
  isMenuBarMobileOpen: boolean;
  toolBarRef: React.RefObject<HTMLDivElement>;
  windowInnerWidth: any;
  moreContainerRef: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationBarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  hotelBookingCallId: string = '';
  categoriesApiCallId: string = ''; 
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.FormTypeMessage)
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      switchLanguage: localStorage.getItem('lang') === 'ar',
      selectedMore: null,
      openPopover: {},
      openFormDialog: null,
      submitDialog: null,
      isMenuBarMobileOpen: false,
      categories: {},
      toolBarRef: React.createRef<HTMLDivElement>(),
      moreContainerRef: {},
      windowInnerWidth: '',
    };
    // Customizable Area End

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  successApiRequestCallBack = <T,>(apiRequestCallId:string, responseJson: {data: T}) => {
    if (apiRequestCallId === this.hotelBookingCallId) {
      this.setState(prevState => ({ submitDialog: prevState.openFormDialog, openFormDialog: null}));
    }else if(apiRequestCallId === this.categoriesApiCallId) {
      const data = responseJson.data as unknown as Array<SubMenuType>
      let objMenuSubMenu:{[key:string]: Array<SubMenuType>} = {}
      for(const category of data) {
        const {attributes} = category
        if(attributes.section in objMenuSubMenu) {
          objMenuSubMenu[attributes.section].push(category)
        }else {
          objMenuSubMenu[attributes.section] = [category]
        }
      }
      this.setState({categories: objMenuSubMenu})
    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson?.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`);
        return;
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isValidResponse<Array<SubMenuType>>(responseJson)) {
        this.successApiRequestCallBack(apiRequestCallId, responseJson)
      } else if (this.isInValidResponse(responseJson)) {
        notification["error"] ({
          message: "Request invalid"
        })
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }else if(getName(MessageEnum.FormTypeMessage) === message.id) {
      const formType = message.getData(getName(MessageEnum.FormTypeMessageData));
      this.handleOpenFormDialog(formType)
    }
  }
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  isValidResponse = <T,>(responseJson: {data: T}) => {
    return !!responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidResponse = (responseJson: {errors: []}) => {
    return !!responseJson.errors;
  };
  // Customizable Area End
  // Customizable Area Start
  getCategories = (section:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesEndpoint(section)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End

  // Customizable Area Start
  handleCategory = (res: string) => {
    const {history} = this.props;
    switch (res) {
      case "our_story": history.push("/story")
        break;
      case "our_vision": history.push("/vision")
        break;
      case "our_mission": history.push("/mission")
        break;
      case "our_upcoming_hotels": history.push("/upcoming-hotels")
        break;
      case "our_hotels": history.push("/hotels")
        break;
      case "request_form": this.customFormCallBack('Franchise')
        break;
      case "download": history.push("/downloads")
    }
  }

  handleSwitchLanguage = () => {
    this.setState(prev => ({ ...prev, switchLanguage: !prev.switchLanguage }), () => {
      localStorage.setItem('lang', this.state.switchLanguage ? 'ar' : 'en')
      location.reload()
    })
  }
  handleOpenPopover = (key: string, target: Element | null) => {
    if(target) {
      this.setState(prevState => ({...prevState, openPopover: {...prevState.openPopover, [key]: target}}), () => {
        this.getCategories(key);
      })
      return;
    }
    this.setState(prevState => ({ ...prevState, openPopover: { ...prevState.openPopover, [key]: target }, categories: {[key]: undefined} }))
    this.handleCloseMenuMobile()
  }

  handleSelectedMoreMenu = (menu: string | null) => {
    if (menu === "terms") {
      const termsMessage = new Message(getName(MessageEnum.TermsConditionMessage))
      termsMessage.addData(getName(MessageEnum.TermsConditionMessageData), true)
      runEngine.sendMessage(termsMessage.id, termsMessage)
      this.handleOpenPopover(configJSON.more, null)
      this.setState({ selectedMore: null })
    }
    else
      this.setState({ selectedMore: menu })
  }

  handleOpenMenuMobile = () => {
    this.setState({isMenuBarMobileOpen: !this.state.isMenuBarMobileOpen,})
  }

  handleCloseMenuMobile = () => {
    this.setState({isMenuBarMobileOpen: false}) 
  }

  handleOpenFormDialog = (formType: FormType | null) => {
    if(formType === 'Room'){
      this.setState({ openFormDialog: null });
    }else {
      this.setState({ openFormDialog: formType })
    }
  }
  handleSubmitDialog = (formType: FormType| null) => {
    this.setState(prev => ({ ...prev, submitDialog: null }))
    this.customFormCallBack(formType)
  }

  // Customizable Area End
  handleFormSubmit = (formData: unknown) => {
    switch (this.state.openFormDialog) {
      case 'Holiday':
      case "Room":
      case 'Book': this.handleBookHotel(formData)
        break;
      case 'Franchise': this.handleFranchiseForm(configJSON.franchiseFormEndpoint, formData, "iris_franchise_forms")
        break;
      case 'Development': this.handleFranchiseForm(configJSON.developmentFormEndpoint, formData, "navigation_menu")
        break;
    }
  }

  handleFranchiseForm = (endPoint:string, formData: unknown, key: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hotelBookingCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      { 'Content-Type': configJSON.exampleApiContentType }
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ [key]: formData })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleBookHotel = (formData: unknown) => {
    formData = { ...formData as object}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hotelBookingCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hotelBookEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      { 'Content-Type': configJSON.exampleApiContentType }
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ourbrandNavigate = () => {
    this.handleCloseMenuMobile()
    this.props.history.push("/brands")
  }

  navigateToWellnessAndHealth = () => {
    this.handleCloseMenuMobile()
    this.props.history?.push("/wellnessAndHealth")
  }

  handleBookNow = () => {
    const message = new Message(getName(MessageEnum.SelectedHotelMessage))
    message.addData(getName(MessageEnum.SelectedHotelMessageData), null);
    runEngine.sendMessage(message.id, message);
    this.customFormCallBack('Book');
  }
  closeCustomForm = (formType: FormType | null = null) => {
    this.customFormCallBack(null)
  }

  handleGoToHoliday = ()=>{
    this.handleCloseMenuMobile()
    this.props.history.push('/holidays')
  }

  handleGoToPromotions = () =>{
    this.handleCloseMenuMobile()
    this.props.history.push('/promotions')
  }

  handleGoToContactUs = () =>{
    this.handleCloseMenuMobile()
    this.props.history.push('/contact-us')
  }


  customFormCallBack = (formType: FormType|null) => {
    this.handleCloseMenuMobile()
    const message = new Message(getName(MessageEnum.FormTypeMessage));
    message.addData(getName(MessageEnum.FormTypeMessageData), formType);
    runEngine.sendMessage(message.id, message);
  }
}
// Customizable Area End