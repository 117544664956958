import React from 'react';
import {createTheme, ThemeProvider} from '@material-ui/core/styles'
import {Dialog, Grid, Box, Typography, Button} from '@material-ui/core'
import {HighlightOffRounded} from "@material-ui/icons"
import { FormType, configJSON } from '../../../blocks/customform3/src/Customform3Controller.web';
const cloud = require("./Vector.svg");
const confetti = require('./confetti 1.svg')

const {translate} = configJSON
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
          },
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 770,
          lg: 1200,
          xl: 1536,
        }
      },
    typography: {
        subtitle1: {
            color: '#fff',
            fontSize: '1.5rem',
            fontWeight: 600,
            lineHeight: '2.25rem'
        },
        subtitle2: {
            color: '#363636',
            fontSize: '1.75rem',
            fontWeight: 600,
            lineHeight: '2.625rem'
        },
        body1: {
            color: '#646464',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5rem'
        },
        caption: {
            color: '#363636',
            fontSize: '0.75rem',
            fontWeight: 600,
            lineHeight: '1.3125rem'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                letterSpacing: '0'
            }
        }
    }
})
interface Props {
    open: FormType | null
    handleMore: (formType: FormType |null) => void
    handleClose: (formType: FormType | null) => void
}

const title: {[key in FormType | 'true']: (lang:string) => string} = {
    Book: (lang: string) => translate.IRIS_HOTEL_SUBMIT[lang],
    Development: (lang: string) => translate.IRIS_DEV_SUBMIT[lang],
    Franchise: (lang:string) =>  translate.IRIS_FRANCHISE_SUBMIT[lang],
    Holiday: (lang: string) => translate.IRIS_HOLIDAY_SUBMIT[lang],
    true: (lang:string) =>  translate.GENERIC_SUBMIT[lang],
    Room: (lang: string) => translate.IRIS_HOLIDAY_SUBMIT[lang]
}
export default function Submit({open, handleMore, handleClose}: Readonly<Props>) {
    const lang = localStorage.getItem('lang') ?? 'en'
    return (
        <ThemeProvider theme={theme}>
            <Dialog open={!!open} fullWidth classes={{
                container: 'submit-dialog-container',
                paper: 'submit-dialog-paper'
            }}>
               <Grid container direction='column' wrap='nowrap' className='submit-grid'>
                    <Grid item container direction='row' justifyContent='space-between'  wrap='nowrap'>
                        <Box position={'relative'} left= {'3.69rem'}>
                            <img src={cloud} />
                        </Box>
                        <Box onClick={() => handleClose(null)}>
                            <HighlightOffRounded htmlColor='#646464' cursor={'pointer'} width={'2.25rem'} height={'2.25rem'}/>
                        </Box>
                    </Grid>
                    <Box alignSelf={'center'} position={'relative'} bottom={'1.7rem'}>
                        <img src={confetti} />
                    </Box>
                    <Box alignSelf={'center'} maxWidth={'24.125rem'}>
                        <Typography  align='center' variant='subtitle2'>{title[open as FormType]?.(lang)}</Typography>
                    </Box>
                    <Box alignSelf={'center'} maxWidth={'21.5rem'} marginTop={'0.75rem'}>
                        <Typography align='center' variant='body1'>{translate.EMAIL_NOTIFY[lang]}</Typography>
                    </Box>
                    <Box alignSelf={'center'} marginTop={'1.25rem'}>
                        <Button classes={{
                            root: 'submit-button'
                        }} onClick={() =>handleMore(open)} data-testid ='book-more'>{translate.BOOK_MORE[lang]}</Button>
                    </Box>
                    <Box alignSelf={'flex-end'} position='relative' bottom={'2rem'}>
                        <img src={cloud} />
                    </Box>
                </Grid>
        </Dialog>
        </ThemeProvider>
        
    )
}