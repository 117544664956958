Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";
exports.more = 'more';
exports.hotels = 'hotels';
exports.language = 'language'
exports.franchise = 'iris_franchise'
exports.promotions = 'promotions'
exports.ourHotelsApiEndPoint = (id) =>`bx_block_catalogue/catalogues/hotels_catalogue_list?category_id=${id}`
exports.hotelBookEndpoint = 'bx_block_request_form/hotel_booking_request_forms/hotel_request_form'
exports.postApiMethod = 'POST'
exports.hotelCatgoriesEndpoint = `bx_block_categories/categories?section=hotels`
exports.ourHotels = 'our_hotels'
exports.irisHolidays = 'holidays'
exports.ourUpcomingHotels = 'our_upcoming_hotels'
exports.contactUs = 'contact_us'
exports.irisDevelopment = 'development'
exports.our_brands = "our_brands";
exports.ourVisionEndPoint = "bx_block_carousel/carousels/landing_page_carousel?carousel_type=our_vision_aligned";
exports.ourMissionCarouselEndPoint = "bx_block_carousel/carousels/landing_page_carousel?carousel_type=our_mission"
exports.ourVisionCarouselEndPoint = "bx_block_carousel/carousels/landing_page_carousel?carousel_type=our_vision_unaligned"
exports.wellness_health = "wellness_health"
exports.franchiseFormEndpoint = 'bx_block_custom_form/iris_franchise_forms/iris_franchise_request_form'

exports.developmentFormEndpoint = 'bx_block_navmenu/navigation_menu/iris_development_form'

exports.categoriesEndpoint = (section) => `bx_block_categories/categories?section=${section}`
exports.contactDetailsApiEndPoint = 'bx_block_contact_us/contacts/getting_phone_number';

exports.about_us = "about_us"
// Define the regular expression pattern using RegExp object
const pattern = /^\+?[1-9]\d{7,14}$/;

exports.validatePhoneNumber = (phoneNumber) => {

  // Test the phone number against the pattern
  return pattern.test(phoneNumber)
}

exports.translate = {
  more: {
    en: 'More',
    ar: 'أكثر'
  },
  searchPlaceholder: {
    ar: 'ابحث عن فندقك ....',
    en: 'Search your hotel....'
  },
  book: {
    ar: 'احجز الآن',
    en: 'Book Now'
  },
  en: {
    en: 'English',
    ar: 'إنجليزي'
  },
  ar: {
    en: 'Arabic',
    ar: 'عربي'
  },
  Select_language: {
    en: 'Select Language',
    ar: 'اختار اللغة'
  },
  Explore: {
    en: 'Explore',
    ar: 'يستكشف'
  },
  No_content_available: {
    en: 'No content available',
    ar: 'لا يوجد محتوى متاح'
  },
  READ_MORE: {
    en: 'Read More',
    ar: 'اقرأ أكثر'
  },
  EXPLORE_HOTELS: {
    ar: 'اكتشف الفنادق',
    en: 'Explore Hotels'
  },
  EXPLORE_MORE: {
    ar: 'استكشاف المزيد',
    en: 'Explore More'
  },
  TREND_HOTELS: {
    en: 'Trending Hotels',
    ar: 'الفنادق الرائجة'
  },
  CLASS_TREND_HOTELS: {
    en: 'Our Classy and trending hotels',
    ar: 'فنادقنا الأنيقة والرائجة'
  },
  HOME_TREND_HOTELS: {
    ar: "نحن نؤمن بأن السفر يعني أكثر من مجرد العثور على مكان للإقامة. يتعلق الأمر بخلق ذكريات عزيزة واكتشاف وجهات نظر جديدة.",
    en: "We believe that travel is about more than just finding a place to stay. It's about creating cherished memories and discovering new perspectives."
  },
  HOTEL_AMENITIES: {
    en: 'HOTEL AMENITIES',
    ar: 'فندق الراحة'
  },
  AMENITIES_SUB: {
    ar: 'وسائل الراحة التي نقدمها لعملائنا',
    en: 'The Amenities we provide to our customers'
  },
  AMENITIES_DESC: {
    ar: 'نحن نؤمن بأن السفر يعني أكثر من مجرد العثور على مكان للإقامة. يتعلق الأمر بخلق ذكريات عزيزة واكتشاف وجهات نظر جديدة.',
    en: "We believe that travel is about more than just finding a place to stay. It's about creating cherished memories and discovering new perspectives."
  },
  CONTACT_US: {
    en: 'Contact Us',
    ar: 'اتصل بنا'
  },
  CONTACT_US_SUB: {
    en: 'Need assistance? We are always there to help you!',
    ar: "تحتاج مساعدة؟ نحن دائما هنا لمساعدتك"
  },
  CONTACT_US_DESC: {
    en: "We believe that travel is about more than just finding a place to stay. It's about creating cherished.",
    ar: "نحن نؤمن بأن السفر يعني أكثر من مجرد العثور على مكان للإقامة. يتعلق الأمر بخلق العزيزة."
  },
  ALL_RIGHTS: {
    en: 'All rights reserved.',
    ar: '.كل الحقوق محفوظة'
  },
  FIRST_NAME: {
    en: 'First Name',
    ar: 'الاسم الأول'
  },
  LAST_NAME: {
    en: 'Last Name',
    ar: 'اسم العائلة'
  },
  CONTACT_NO: {
    en: 'Contact #',
    ar: 'رقم الاتصال'
  },
  EMAIL_ID: {
    en: 'E-Mail ID',
    ar: 'عنوان البريد الإلكتروني'
  },
  MESSAGE: {
    en: 'Message (if any)',
    ar: 'الرسالة (إن وجدت)'
  },
  SUBMIT: {
    en: 'Submit',
    ar: 'يُقدِّم'
  },
  ASSISTANCE_SUB: {
    en: 'Need more assistance?',
    ar: 'هل تحتاج إلى المزيد من المساعدة؟'
  },
  ASSISTANCE_DESC: {
    en: 'Call our customer support or chat directly on Whatsapp',
    ar: 'Whatsapp اتصل بدعم العملاء لدينا أو قم بالدردشة مباشرة على'
  },
  ABOUT_US: {
    en: 'About Us',
    ar: 'معلومات عنا'
  },
  IRIS_FRANCHISE: {
    en: 'Iris Franchise',
    ar: 'امتياز ايريس'
  },
  IRIS_DEVELOPMENT: {
    en: 'Iris Development',
    ar:'تطوير ايرس'
  },
  OUR_BRANDS: {
    en: 'Our Brands',
    ar: 'علاماتنا التجارية'
  },
  HOTELS: {
    en: 'Hotels',
    ar: 'الفنادق'
  },
  WELLNESS_HEALTH: {
    en: 'Wellness Health & Spa',
    ar: 'العافية والصحة والسبا'
  },
  PROMOTIONS: {
    en: 'Promotions',
    ar: 'الترقيات'
  },
  IRIS_HOLIDAY: {
    en: 'Iris Holidays',
    ar: 'ايريس للعطلات'
  },
  TERMS_CONDITION: {
    en: 'Terms & Conditions',
    ar:'لأحكام والشروط'
    },
  ROOM: {
    en: 'Rooms',
    ar: 'غرف'
  },
  BATHROOM: {
    en: 'Bathrooms',
    ar: 'الحمامات'
  },
  RESTAURANT: {
    en: 'Restaurants',
    ar: 'مطاعم'
  },
  POOL: {
    en: 'Pool',
    ar: 'حمام سباحة'
  },
  GYM: {
    en: 'Gym',
    ar: 'نادي رياضي'
  },
  MED_SITE: {
    en: 'Meditation site',
    ar: 'موقع التأمل'
  }
}
// Customizable Area End