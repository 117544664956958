import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { getApiCalls } from "../../catalogue/src/utils";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { HotelCollectionType } from "../../catalogue/src/OurHotelsController.web";
import { SectionHotelsInterface } from "../../landingpage/src/Interfaces/LandingInterface";
import { message as MESSAGE, notification } from "antd";
import { PlanType } from "../../catalogue/src/CatalogueController.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

const {translate} = configJSON

export type FormType = 'Book' | 'Franchise' | 'Development' | 'Holiday' |'Room'

export type FormContentKeys = 'label_name1' | 'label_name2' | 'label_name3' | 'label_name4' | 'label_name5' | 'label_name6' | 'label_name7' | 'label_name8' | 'label_name9' | 'label_name10'
| 'arabic_label_name1' | 'arabic_label_name2' | 'arabic_label_name3' | 'arabic_label_name4' | 'arabic_label_name5' | 'arabic_label_name6' | 'arabic_label_name7' | 'arabic_label_name8' | 'arabic_label_name9' | 'arabic_label_name10'
export interface FormDataType {
    [key:string]: any
}

export interface DirtyType {
    [key:string]: boolean
}
export interface Props {
  // Customizable Area Start
  handleClose: (formType: FormType | null) => void,
  formType: FormType | null
  handleSubmit: (formData: unknown) => void
  // Customizable Area End
}

export type FormContentType = {
  [key in FormContentKeys]: string;
};

interface S {
  // Customizable Area Start
  formData: FormDataType
  dirty: DirtyType
  hotelCollections: HotelCollectionType[]
  selectedPlan: PlanType | null
  formContent: {data: FormContentType | {}, loading: boolean}
  // Customizable Area End
}

export const formKeys = {
    Book: ['catalogue_id', 'fullname', 'start_date', 'end_date','email_address','contact', 'no_of_adults', 'no_of_kids', 'feed_back'],
    Franchise: ['title', 'full_name', 'phone_number', 'email', 'bussiness_type', 'intrested_service','description'],
    Holiday: ['catalogue_id', 'fullname', 'start_date', 'end_date','email_address','contact', 'no_of_adults', 'no_of_kids', 'feed_back'],
    Development:['title', 'full_name', 'phone_number', 'email', 'bussiness_type', 'intrested_service','description'],
    Room: ['catalogue_id', 'fullname', 'start_date', 'end_date','email_address','contact', 'no_of_adults', 'no_of_kids', 'feed_back'],
}

export const title: {[key: string]: (lang:string) => string} = {
    Book: (lang:string) => translate.BOOK_HOTEL[lang],
    Franchise: (lang:string) => translate.FRANCHISE_ENQ[lang],
    Development:(lang:string) => translate.IRIS_DEV_ENQ[lang],
    Holiday: (lang:string) => translate.BOOK_HOLIDAY[lang],
    Room: (lang:string) => translate.BOOK_ROOM[lang]
    
}

const autoFillFields = ['catalogue_id', 'plan_id', 'no_of_adults']

export const catalogueFieldName: {[key in FormType]: (lang:string) =>string} = {
  'Book': (lang:string) => translate.HOTEL_NAME[lang],
  'Holiday': (lang:string) => translate.TITLE[lang],
  'Development': (lang:string) => translate.TITLE[lang],
  'Franchise': (lang:string) => translate.TITLE[lang],
  'Room': (lang:string) => translate.TITLE[lang],
}

const adminFormType: {[key in FormType]: string[]} = {
  Book: ['book_now_form','hotel_booking_request_form'],
  Development: ['form_fields_name','iris_development_form'],
  Franchise: ['form_fields_name','iris_franchise_form'],
  Holiday: ['book_now_form','holiday_booking_request_form'],
  Room: ['book_now_form','holiday_booking_request_form']
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customform3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  hotelDataApiCallId = ''
  hotelCategoriesCallId = ''
  formContentApiCallId = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SelectedHotelMessage),
      getName(MessageEnum.SelectedAdultMessage),
      getName(MessageEnum.FormTypeMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formData: {},
      dirty: {},
      hotelCollections: [],
      selectedPlan: null,
      formContent: {data: {}, loading: false}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (responseJson?.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isValidResponse<HotelCollectionType[]| SectionHotelsInterface | null>(responseJson)) {
        this.successCallBack(apiRequestCallId, responseJson.data)
       
      } else if (this.isInValidResponse(responseJson)) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }else if(getName(MessageEnum.SelectedHotelMessage) === message.id) {
      this.selectHotelOperation(message)
    }else if(getName(MessageEnum.SelectedAdultMessage) === message.id) {
      this.selectedAdultOperation(message)
    }else if(getName(MessageEnum.FormTypeMessage) === message.id) {
      this.formTypeOperation(message)
    }
    // Customizable Area End
  }

  successCallBack = (apiRequestCallId: string, data: HotelCollectionType[] | SectionHotelsInterface['data'] | {attributes: FormContentType}[]) => {
    if(apiRequestCallId === this.hotelDataApiCallId) {
      this.setState(prev => ({hotelCollections: data as HotelCollectionType[] }))
    }else if(apiRequestCallId === this.hotelCategoriesCallId) {
      const hotelCategories = [...data as SectionHotelsInterface['data']]
      const foundOurHotel = hotelCategories.find((item) => item.attributes.category_type === configJSON.ourHotels)
      if(foundOurHotel){
        this.getOurHotels(foundOurHotel.id)
      }
    }else if(apiRequestCallId === this.formContentApiCallId) {
      if(data.length) {
        const {attributes} = data[0]
        this.setState(prev => ({...prev, formContent: {...prev.formContent, data: attributes as FormContentType, loading: false}}))
      }else {
        this.setState(prev => ({...prev, formContent: {...prev.formContent, data: {}, loading: false}}))
      }
    }
  }
  setFormWithVat = (selectedHotel:any)=>{
    const lang = localStorage.getItem('lang') ?? 'en';
    return selectedHotel[0]?.attributes?.description&&selectedHotel[0]?.attributes?.currency&&selectedHotel[0]?.attributes?.price?translate.EX_VAT_TAX[lang]:''
  }
  selectHotelOperation = (message: Message) => {
    const selectedHotel = message.getData(getName(MessageEnum.SelectedHotelMessageData));
    if(!selectedHotel) {
      this.getHotelCategories();
    }else {
      this.setState(prev => ({...prev,hotelCollections: selectedHotel, formData: {...prev.formData, catalogue_id: selectedHotel[0].id,feed_back: `${selectedHotel[0]?.attributes?.description!==null?selectedHotel[0]?.attributes?.description:''} ${selectedHotel[0]?.attributes?.currency?selectedHotel[0]?.attributes?.currency:''} ${selectedHotel[0]?.attributes?.price?selectedHotel[0]?.attributes?.price:''} ${this.setFormWithVat(selectedHotel)}` }}))
    }
  }
  selectedAdultOperation = (message: Message) => {
    const selectedAdult: PlanType = message.getData(getName(MessageEnum.SelectedAdultMessageData));
    if(selectedAdult) {
      this.setState(prev =>({...prev, selectedPlan: selectedAdult, formData: {...prev.formData, plan_id: selectedAdult.id}}))
    }
  }

  formTypeOperation = (message: Message) => {
    const formType = message.getData(getName(MessageEnum.FormTypeMessageData));
      if(!formType) {
        this.setState({dirty: {}, formData:{}})
      }else {
        this.formContentApiCallId = getApiCalls(configJSON.customFormContentEndPoint,() => {
          this.setState(prev => ({
            ...prev, formContent: {...prev.formContent, data:{}, loading: true}
          }))
        }, ...adminFormType[formType as FormType])
        this.setState(prev => {
          const newFormData = autoFillFields.reduce((acc, curr) => {
            if(curr in prev.formData){
              acc[curr] = prev.formData[curr]
            }
            return acc;
          }, {} as FormDataType)
          
          return {...prev, dirty: {}, formData: {...newFormData}}
        })
      }
  }
  isValidResponse = <T,>(responseJson: {data: T}):boolean => {
    return !!responseJson.data;
  };

  isInValidResponse = (responseJson: {errors?: string[]}) => {
    return !!responseJson.errors;
  };

  handleTernary = (predicate:boolean, answer: string, reject: string) => {
    return predicate ? answer:reject;
    }

  handleChange = (e:React.ChangeEvent<{name?:string, value:unknown}>) => {
    const {name, value} = e.target
    this.setState(prev => {
      let dirtyObj = {}
      if(Object.keys(prev.dirty).length) {
        dirtyObj =  this.isFailValidation({...prev.formData, [name as string]:value}, this.props.formType as FormType)
      }
      return{...prev, formData: {...prev.formData, [name as string]:value}, dirty: dirtyObj}
    })
    }

  handleValidationAndSubmit = () => {
    const dirtyObj = this.isFailValidation(this.state.formData, this.props.formType as FormType)
    if(Object.values(dirtyObj).includes(true)){
        this.setState({dirty: dirtyObj})
        return;
    }
    this.props.handleSubmit(this.state.formData)
}

  isFailValidation = (formData: FormDataType, formType: FormType) => {
    let dirtyObj: {[key:string]: boolean} = {}
    for(const key of formKeys[formType]) {
      if(!(key in formData)) {
        dirtyObj[key] = true;
      } else {
        switch(key) {
          case 'email':
          case 'email_address': {
            dirtyObj[key] = !configJSON.validateEmail(formData[key] as string);
            break;
          }
          case 'feed_back':
          case 'description': 
          case 'full_name':
          case 'fullname':{
            const text = formData[key] as string 
            dirtyObj[key] = !text.trim()
            break;
          }
          case 'end_date':
          case 'start_date': {
            const date = formData[key] as string | null
            if(!date){
                dirtyObj[key] = true
            }else if(isNaN(Date.parse(date))) {
                dirtyObj[key] = true
            }else {
                dirtyObj[key] = false
                }
            break;
        }
          case 'contact':
          case 'phone_number': {
            dirtyObj[key] = !configJSON.validatePhoneNumber(formData[key] as string)
            break;
          }
        }
      }
    }
    return dirtyObj
  }

  // Customizable Area Start

  getOurHotels = (id:number) => {
    this.hotelDataApiCallId = getApiCalls(configJSON.ourHotelsApiEndPoint, () => {}, id.toString())
  }
  
  getHotelCategories = () => {
    this.hotelCategoriesCallId = getApiCalls(configJSON.hotelCatgoriesEndpoint, () => {})
  }

  handleEmpty = (first: string, second: string) => first || second;
  // Customizable Area End
}
