//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
import NavigationBar from '../../blocks/landingpage/src/NavigationBar.web';
//* Routes List start


const LandingPageWeb = lazy(() =>
  import('../../blocks/landingpage/src/LandingPage.web')
);

const AboutUsOurStory = lazy(() =>
  import('../../blocks/landingpage/src/AboutUs_OurStory.web')
);

const OurHotel = lazy(() =>
  import('../../blocks/catalogue/src/OurHotels.web.tsx')
);


const UpcomingHotelWeb = lazy(() => import('../../blocks/catalogue/src/UpcomingHotels.web'))

const HotelDetailsWeb = lazy(() => import('../../blocks/catalogue/src/HotelDetails.web'))

const PromotionHolidayWeb = lazy(() => import('../../blocks/catalogue/src/PromotionHoliday.web'))

const PromotionHolidayDetailsWeb = lazy(() => import('../../blocks/catalogue/src/PromotionHolidayDetails.web'))

const OurVision = lazy(() => import('../../blocks/landingpage/src/OurVision.web'));

const AboutUsOurMission = lazy(() => import('../../blocks/landingpage/src/AboutUs_OurMission.web'));

const ContactUsWeb = lazy(() => import('../../blocks/contactus/src/ContactUs2.web'));

const OurBrands = lazy(() => import('../../blocks/navigationmenu/src/Ourbrand.web'));

const DownloadWeb = lazy(() => import('../../blocks/download/src/Download.web'))

const WellnessHealth = lazy(() => import('../../blocks/navigationmenu/src/WellnessHealth.web'));

const ElasticSearch = lazy(() => import('../../blocks/ElasticSearch/src/ElasticSearch.web'))
// /* Routes List End /

// /* Private Routes start /
// import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';

// /* Private Roues End /

function WebRoutes(rootProps: any) {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <NavigationBar displaySpinner={undefined} hideLoader={undefined} showLoader={undefined} id={''} navigation={undefined} classes={undefined} />
        <Switch>
          <Route exact path={'/upcoming-hotels'} render={props => <UpcomingHotelWeb {...props}/>} />
          <Route  exact path={'/promotions'} render={props => <PromotionHolidayWeb {...props}/>}/>
          <Route path={'/hotels/:id'} render={props => <HotelDetailsWeb {...props}/>} />
          <Route path={'/promotions/:id'} render={props => <PromotionHolidayDetailsWeb {...props}/>} />
          <Route
            exact
            path={'/hotels'}
            render={props => <OurHotel {...props} />} />
          <Route
            path={'/hotels/:id'}
            render={props => <HotelDetailsWeb {...props} />} />
          <Route
            exact
            path="/"
            render={props => <LandingPageWeb {...props} />}
          />
          <Route 
            path="/story"
            exact
            render={props => <AboutUsOurStory {...props} />}
          />
            <Route
            path="/vision"
            exact
            render={props => <OurVision {...props} />}
          />
              <Route
            path="/mission"
            exact
            render={props => <AboutUsOurMission {...props} />}
          />
           <Route
            path="/brands"
            exact
            render={props => <OurBrands {...props} />}
          />
            <Route
            path="/wellnessAndHealth"
            exact
            render={props => <WellnessHealth {...props} />}
          />
          <Route exact path='/holidays' render={props => <PromotionHolidayWeb {...props}/>} />
          <Route path='/holidays/:id' render={props => <PromotionHolidayDetailsWeb {...props}/>} />
          <Route exact path='/contact-us' render={props => <ContactUsWeb {...props}/>} />
          <Route exact path='/downloads' render={props => <DownloadWeb {...props}/>} />
          <Route path="/search/:name" render={props => <ElasticSearch {...props}/> } />
        </Switch>
      </Suspense>
    </Router>
  );
}

//@ts-ignore
export default withRouter(WebRoutes);
