Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.postApiMethod = "POST"
exports.hotelDetailsApiEndPoint = (id) => `bx_block_product_description/product_descriptions/catalogue_details?catalogue_id=${id}`;
exports.ourHotelsApiEndPoint = (id) =>`bx_block_catalogue/catalogues/hotels_catalogue_list?category_id=${id}`
exports.hotelCatgoriesEndpoint = `bx_block_categories/categories?section=hotels`
exports.ourHotels = 'our_hotels'
exports.holidayPageEndpoint = `bx_block_iris_holyday/iris_holydays`
exports.holidayBookEndpoint = 'bx_block_request_form/hotel_booking_request_forms/hotel_request_form'
exports.filterPackageCatEndpoint = (category) =>`bx_block_catalogue/catalogues/filter_package_category?package_category=${category}`
exports.promotionPageEndpoint = 'bx_block_promotions/promotions'
exports.ourUpcomingHotels = 'our_upcoming_hotels'

exports.contentManagementEndpoint = (categoryType) => `bx_block_content_management/content_managements/getting_all_content?category_type=${categoryType}`;

exports.translate = {
  OUR_UPCOMING_HOTELS: {
    en: 'our upcoming hotels',
    ar: 'فنادقنا القادمة'
  },
  OUR_UPCOMING_HOTELS_SUB: {
    en: 'An exquisite fusion of luxury and sophistication awaits at the upcoming hotel',
    ar: 'مزيج رائع من الفخامة والرقي ينتظرك في الفندق القادم'
  },
  COMING_SOON: {
    en: 'coming soon',
    ar: 'قريباً'
  },
  HOTEL_CONSIST: {
    en: 'The hotel consists of the following:',
    ar: ':يتكون الفندق مما يلي'
  },
  OUR_HOTELS: {
    en: 'Our Hotels',
    ar: 'فنادقنا'
  },
  OUR_HOTELS_SUB: {
    en: 'Our world class hotels are here for your comfortable stay',
    ar: 'فنادقنا ذات المستوى العالمي موجودة هنا من أجل إقامتك المريحة'
  },
  ROOM_TARIFF: {
    en: 'Rooms & Tariffs',
    ar: 'الغرف والتعريفات'
  },
  AMENITIES: {
    en: 'Amenities',
    ar: 'وسائل الراحة'
  },
  CONTACT: {
    en: 'Contact Details',
    ar: 'بيانات المتصل'
  },
  EXPLORE: {
    en: 'Explore',
    ar: 'يستكشف'
  },
  OUR_TARIFF: {
    en: 'our tariffs',
    ar: 'التعريفات لدينا'
  },
  EVERY_ABOUT: {
    en: 'Everything about',
    ar: 'كل شيء عنه'
  },
  FACILITY_AMENITIES: {
    en: 'Hotel facilities & Room Amenities',
    ar: 'مرافق الفندق ووسائل الراحة في الغرفة'
  },
  CONTACT_DESC: {
    en: 'Contact Details for Booking',
    ar: 'تفاصيل الاتصال للحجز'
  },
  'Contact Person': {
    en: 'Contact Person',
    ar: 'الشخص الذي يمكن الاتصال به'
  },
  'E-Mail Address': {
    en: 'E-Mail Address',
    ar: 'عنوان البريد الإلكتروني'
  },
  'Telephone': {
    en: 'Telephone',
    ar: 'هاتف'
  },
  BEST_DEST_HEADER: {
    en: 'Book your package to our best Destinations in this Summer',
    ar: 'احجز باقتك إلى أفضل الوجهات لدينا في هذا الصيف'
  },
  BEST_DEST_DESC: {
    ar: '.مهمتنا في فنادق IRIS هي تقديم خدمات ضيافة لا مثيل لها تتجاوز توقعات ضيوفنا. نحن نسعى جاهدين لخلق بيئة مرحبة ومضيافة تعزز النجاح',
    en: 'At IRIS Hotels, our mission is to provide unparalleled hospitality services that exceed the expectations of our guests. We strive to create a welcoming and hospitable environment that promotes successful.'
  },
  BEST_HOTEL_HEADER: {
    en: 'Book your package to our best Hotels in this Summer',
    ar: 'احجز باقتك في أفضل فنادقنا في هذا الصيف'
  },
  BEST_HOTEL_DESC: {
    en: 'At IRIS Hotels, our mission is to provide unparalleled hospitality services that exceed the expectations of our guests. We strive to create a welcoming and hospitable environment that promotes successful.',
    ar: 'مهمتنا في فنادق IRIS هي تقديم خدمات ضيافة لا مثيل لها تتجاوز توقعات ضيوفنا. نحن نسعى جاهدين لخلق بيئة مرحبة ومضيافة تعزز النجاح.'
  },
  FAV_DEST_HEADER: {
    en: 'Book your package to our all time Favourite destinations',
    ar: 'احجز باقتك إلى وجهاتنا المفضلة طوال الوقت'
  },
  FAV_DEST_DESC: {
    en: 'At IRIS Hotels, our mission is to provide unparalleled hospitality services that exceed the expectations of our guests. We strive to create a welcoming and hospitable environment that promotes successful.',
    ar: 'مهمتنا في فنادق IRIS هي تقديم خدمات ضيافة لا مثيل لها تتجاوز توقعات ضيوفنا. نحن نسعى جاهدين لخلق بيئة مرحبة ومضيافة تعزز النجاح.'
  },
  DESC: {
    en: 'Description',
    ar: 'وصف'
  },
  NO_DESC_AVLB: {
    en: 'No description available',
    ar: 'لا يوجد وصف متاح'
  },
  SEL_PLAN_TIME: {
    en: 'Select your plan time',
    ar: 'حدد وقت خطتك'
  },
  SEL_ROOM: {
    en: 'Select your rooms',
    ar: 'حدد وقت خطتك'
  },
  PVT_PICKUP_INC: {
    en: 'Private Pickup included',
    ar: 'يشمل النقل الخاص'
  },
  NO_PLAN_AVLB: {
    en: 'No plans available',
    ar: 'لا توجد خطط متاحة'
  },
  ADULTS:(num) => ({
    en: `${num} Adults`,
    ar: `بالغين ${num}`
  }),
  NO_ADD_TAX: {
    en: '(No additional taxes or booking fees)',
    ar: '(لا توجد ضرائب إضافية أو رسوم الحجز)'
  },
  REVIEWS: (num) => ({
    en: `${num} Reviews`,
    ar: `التعليقات ${num}`
  }),
  FIND_MORE: {
    en: 'Find More',
    ar: 'جد أكثر'
  },
  CAREER_OPTIONS: {
    en: 'career options',
    ar: 'الخيارات المهنية'
  },
  RESERVE_NOW: {
    en: 'Reserve Now',
    ar: 'احجز الان'
  },
  BOOK_NOW: {
    ar: 'احجز الآن',
    en: 'Book Now'
  },
  EX_VAT_TAX: {
    en: '(Ex VAT & Taxes)',
    ar: '(باستثناء ضريبة القيمة المضافة والضرائب)'
  },
  NO_CON_AVLB: {
    en: 'No Content Available !',
    ar: '! لا يوجد محتوى متاح'
  },
  PLS_CON_ADM: {
    en: 'Please contact admin.',
    ar: '.يرجى الاتصال بالمسؤول'
  },
  PER_AMOUNT_RATE: (_) => ({
    ar: `بدءًا من $${_} للشخص البالغ (يختلف السعر حسب حجم المجموعة)`,
    en: `from $${_} per adult (price varies by group size)`  
  })
}
// Customizable Area End