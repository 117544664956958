
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ElasticSearch";
exports.labelBodyText = "ElasticSearch Body";

exports.btnExampleTitle = "CLICK ME";

exports.searchApiEndpoint = (title) =>  `bx_block_catalogue/catalogues/results?name=${title}`

exports.translate = {
  SEARCH_PLACEHOLDER: {
    ar: 'ابحث عن فندقك ...',
    en: 'Search Your Hotel...'
  },
  SEARCH_RESULTS: {
    en: 'Search Results',
    ar: 'نتائج البحث'
  },
  NO_RES_FOUND: {
    en: 'No Results Found',
    ar: 'لم يتم العثور على نتائج'
  },
  RES_FOUND: {
    en: 'Results found',
    ar: 'العثور على نتائج'
  },
  VIEW_DETAILS: {
    en: 'View Details',
    ar: 'عرض التفاصيل'
  },
  OOPS_NO_RES_FOUND: {
    en: "Oops!... no results found",
    ar: "عفوًا!... لم يتم العثور على نتائج"
  },
  PLS_AN_SEARCH: {
    en: "Please try another search",
    ar: "من فضلك حاول بحث آخر"
  }
}

exports.debounce = (func, timeOut = 300) => {
  let timer;
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeOut)
  }
}

// Customizable Area End