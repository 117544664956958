Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform3";
exports.labelBodyText = "customform3 Body";

exports.btnExampleTitle = "CLICK ME";

const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

exports.validateEmail = function (email) {
  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64){
    return false;
  }else if (address.length > 255) return false;

  const domainParts = address.split('.');
  
  if (domainParts.some(function (part) {
    return part.length > 63;
  })) return false;

  return tester.test(email);
};

// Define the regular expression pattern using RegExp object
const pattern = /^\+?[1-9]\d{7,14}$/;

exports.validatePhoneNumber = (phoneNumber) => {

  // Test the phone number against the pattern
  return pattern.test(phoneNumber)
}
// ENDPOINTS START
exports.ourHotelsApiEndPoint = (id) =>`bx_block_catalogue/catalogues/hotels_catalogue_list?category_id=${id}`
exports.hotelCatgoriesEndpoint = `bx_block_categories/categories?section=hotels`
exports.customFormContentEndPoint = (type1, type2) => `bx_block_custom_form/iris_franchise_forms/${type1}?form_type=${type2}`
// ENDPOINTS END

exports.ourHotels = 'our_hotels'

exports.translate = {
  TITLE_REQ: {
    en: 'The title is required',
    ar: 'العنوان مطلوب'
  },
  NAME_REQ: {
    en: 'Your name is required',
    ar: 'اسمك مطلوب'
  },
  NAME_INVALID: {
    en: 'Your name is invalid',
    ar: 'اسمك غير صالح'
  },
  CONTACT_INVALID: {
    en: 'The contact number is invalid',
    ar: 'رقم الاتصال غير صالح'
  },
  CONTACT_REQ: {
    en: 'The contact number is required',
    ar: 'رقم الاتصال مطلوب'
  },
  FEED_INVALID: {
    en: 'Feedback is invalid',
    ar: 'ردود الفعل غير صالحة'
  },
  FEED_REQ: {
    en: 'Feedback is required',
    ar: 'ردود الفعل مطلوبة'
  },
  EMAIL_INVALID: {
    en: 'The E-mail address is invalid',
    ar: 'عنوان البريد الإلكتروني غير صالح'
  },
  EMAIL_REQ: {
    en: 'The E-mail address is required',
    ar: 'عنوان البريد الإلكتروني مطلوب'
  },
  FIELD_REQ: {
    en: 'This field is required',
    ar: 'هذه الخانة مطلوبه'
  },
  BOOK_HOTEL: {
    en: 'Book your Hotel Now !',
    ar: '!احجز فندقك الآن'
  },
  FRANCHISE_ENQ: {
    en: 'Franchise Enquiry',
    ar: 'الاستعلام عن الامتياز'
  },
  IRIS_DEV_ENQ: {
    en: 'IRIS Development Enquiry',
    ar: 'استعلام عن تطوير IRIS'
  },
  BOOK_HOLIDAY: {
    en: 'Book your Holiday Now !',
    ar: '!احجز عطلتك الآن'
  },
  BOOK_ROOM: {
    en: 'Book your Hotel Room Now !',
    ar: '!احجز عطلتك الآن'
  },
  IMAGE_TEXT: {
    en: 'Unlock Your Path to Acquiring Our Premier Franchise',
    ar: 'افتح طريقك للحصول على امتيازنا المميز'
  },
  FILL_FORM: {
    en: 'Please fill the following form:',
    ar: ':برجاء ملء النموذج التالي'
  },
  SUBMIT: {
    en: 'Submit',
    ar: 'يُقدِّم'
  },
  TITLE: {
    en: 'Title',
    ar: 'عنوان'
  },
  FULL_NAME: {
    en: 'Full Name',
    ar: 'الاسم الكامل'
  },
  PHONE_NUM: {
    en: 'Phone Number',
    ar: 'رقم التليفون'
  },
  EMAIL: {
    en: 'E-Mail',
    ar: 'بريد إلكتروني'
  },
  CURR_WEB_EX: {
    en: 'Current Website if exists or Business name?',
    ar: 'الموقع الإلكتروني الحالي إن وجد أو اسم العمل؟'
  },
  BUSINESS_TYPE: {
    en: 'What type of business do you have?',
    ar: 'ما نوع العمل الذي لديك؟'
  },
  INTEREST_SERVICE: {
    en: "Please select the services you're interested in?",
    ar: 'الرجاء تحديد الخدمات التي تهمك؟'
  },
  FEEDBACK: {
    en: "Anything else you'd like to tell us before we talk?",
    ar: 'هل هناك أي شيء آخر تود إخبارنا به قبل أن نتحدث؟'
  },
  EMAIL_ADDR: {
    en: 'E-Mail Address',
    ar: 'عنوان البريد الإلكتروني'
  },
  CONTACT: {
    en: 'Contact #',
    ar: '# اتصال'
  },
  NUM_ADULTS: {
    en: 'No. of Adults',
    ar: 'عدد البالغين'
  },
  NUM_KIDS: {
    en: 'No. of Kids',
    ar: 'عدد الاطفال'
  },
  PROMO_CODE: {
    en: 'Promo Code (If any)',
    ar: 'الرمز الترويجي (إن وجد)'
  },
  HOTELS: {
    en: 'Hotels',
    ar: 'الفنادق'
  },
  RESTAURANT: {
    en: 'Restaurant',
    ar: 'مطعم'
  },
  APTMT: {
    en: 'Apartments',
    ar: 'شقق سكنية'
  },
  SPA: {
    en: 'Spa',
    ar: 'منتجع صحي'
  },
  OTHERS: {
    en: 'Others',
    ar: 'آحرون'
  },
  CONSULTATION: {
    en: 'Consultation',
    ar: 'التشاور'
  },
  HOTEL_OPN: {
    en: 'Hotel Operation',
    ar: 'تشغيل الفندق'
  },
  HOTEL_MGMT: {
    en: 'Hotel Management',
    ar: 'إدارة الفندق'
  },
  MR: {
    en: 'Mr.',
    ar: '.السيد'
  },
  MRS: {
    en: 'Mrs.',
    ar: '.السّيدة'
  },
  FEEDBACK_PLACEHOLDER: {
    en: 'Write anything you wanna share with us or for our betterment 😊',
    ar: '😊 اكتب أي شيء تريد مشاركته معنا أو من أجل تحسيننا'
  },
  START_DATE: {
    en: 'Start Date',
    ar: 'تاريخ البداية'
  },
  END_DATE: {
    en: 'End Date',
    ar: 'تاريخ الانتهاء'
  },
  IRIS_HOTEL_SUBMIT: {
    en: 'Your request for Iris hotel has been submitted.',
    ar: '.لقد تم تقديم طلبك الخاص بفندق إيريس'
  },
  IRIS_FRANCHISE_SUBMIT: {
    en: 'Your request for Iris Franchise has been submitted.',
    ar: 'لقد تم تقديم طلبك للحصول على امتياز Iris.'
  },
  IRIS_DEV_SUBMIT : {
    en: 'Your request for Iris Development has been submitted.',
    ar: '.لقد تم تقديم طلبك لتطوير القزحية'
  },
  IRIS_HOLIDAY_SUBMIT: {
    en: 'Your request for Iris Holiday has been submitted.',
    ar: '.لقد تم تقديم طلبك الخاص بـ ايريس هوليداي'
  },
  GENERIC_SUBMIT: {
    en: 'Your request is successfully submitted.',
    ar: '.تم تقديم طلبك بنجاح'
  },
  EMAIL_NOTIFY: {
    en: 'You will receive an email of the same request on your email ID.',
    ar: '.سوف تتلقى رسالة بريد إلكتروني بنفس الطلب على معرف البريد الإلكتروني الخاص بك'
  },
  BOOK_MORE: {
    en: 'Book More',
    ar: 'احجز المزيد'
  },
  EX_VAT_TAX: {
    en: '(Ex VAT & Taxes)',
    ar: '(باستثناء ضريبة القيمة المضافة والضرائب)'
  },
  HOTEL_NAME: {
    en: 'Hotel Name',
    ar: 'اسم فندق'
  }
}
// Customizable Area End