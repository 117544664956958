// Customizable Area Start
import React from 'react';
import {Box, InputBase } from '@material-ui/core'
import { vector, vectorserch1 } from './assets';
import { CancelOutlined } from '@material-ui/icons';
import './styles.css'
import ShowComponent from '../../../components/src/utils/ShowComponent';
import {withRouter} from 'react-router-dom'
import SearchContent from "../../../components/src/searchContent/SearchContent.web"
import SearchInputController, {configJSON} from './SearchInputController.web';
// Customizable Area End

// Customizable Area Start
const {translate} = configJSON

export class SearchInput extends SearchInputController{
    dialog(){
      const {searchResponse: {data, loading}} = this.state
      return (
        <SearchContent data={data} loading={loading} onClose={this.handleClose} onViewDetails={this.handleViewDetails} classes={{
          container: `elastic-dialog-container ${!data?.length ? 'no-search-container': 'search-container'}`
        }}
        view='HALF_PAGE'
        />
        
      )
    }
    
    render() {
        const lang = localStorage.getItem('lang') ?? 'en'
        const {dialogOpen } = this.state
        return (
          <>
            <Box className="SearchBar" height={'max-content'}>
              <Box className="SearchIconContainer1" width={'100%'}>
                <img src={vectorserch1} alt="Search" className="Search1"></img>
                <InputBase
                  placeholder={translate.SEARCH_PLACEHOLDER[lang]}
                  classes={{
                    input: 'search-input',
                    root: 'search-root'
                  }}
                  onChange={(e) =>  {
                    this.debounceSearchInput(e.target.value)}}
                  onKeyDown={this.handleKeyDown}
                  inputRef={this.inputRef}
                  endAdornment={
                    <ShowComponent show={!!this.inputRef.current?.value}>
                      <Box mr={'0.65rem'} onClick={this.clearInput} data-testid="empty-search-text">
                        <CancelOutlined style={webStyle.point} htmlColor='#646464' width={'1.25rem'} height={'1.25rem'} opacity={'0.5'}/>
                      </Box>
                    </ShowComponent>
                  }
                />
              </Box>
              <Box className="SearchIconContainer" style={webStyle.point} data-testid={"search-full-page"} onClick={this.handleSearchClick}>
                <img src={vector} alt="Search" className="Search"></img>
              </Box>
            </Box>
            <ShowComponent show={dialogOpen}>
              {this.dialog()}
            </ShowComponent>
            </>
        )
    }
}

export default withRouter(SearchInput)
const webStyle: {
  [key:string]: {
    [key:string]: string
  }
} = {
  mr: {
    marginRight: '0.25rem'
  },
  mt: {
    marginTop: '2.38rem',
    marginLeft: '-0.75rem'
  },
  point: {
    cursor: 'pointer'
  },
  img: {
    borderRadius: '0.3125rem',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}
// Customizable Area End