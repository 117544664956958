import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {configJSON} from './CatalogueController.web';

export const getApiCalls = (endpoint:(...args:string[]) => string, callBack:() => void,...args:string[]) => {
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      args.length ? endpoint(...args): endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      {'Content-Type': configJSON.productApiContentType}
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    if(callBack) {
      callBack()
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  export const isOverflowText = (node:Element, maxHeight: number) => {
    const styles = window.getComputedStyle(node)
    return (parseFloat(styles.height) / parseFloat(styles.lineHeight)) / maxHeight
  }

  export const debounce = (func: (...args: unknown[]) => void, timeOut = 300) => {
    let timer: number;
    return function (...args: unknown[]) {
      clearTimeout(timer)
      timer = window.setTimeout(() => {
        func(...args)
      }, timeOut)
    }
  }