import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";

export interface ValidResponseType {
  data: object;
}

export interface ValidTokenResponseType {
  meta: { token: string };
}

export interface ErrorPayloadType {
  key: string;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface TermsAndConditionTypes {
  data: [
    {
      id: string;
      type: string;
      attributes: {
        name: string;
        description: string;
        arabic_description:string
      }
    }
  ]
}

export interface TermsAndConditionTypes1 {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    arabic_description:string
  }
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  termsAndConditiondata: Array<TermsAndConditionTypes1>;
  termsAndConditionErrorData: string;
  openDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditions3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termsAndConditionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.TermsConditionMessage),
      // Customizable Area End
    ];

    this.state = {

      // Customizable Area Start
      termsAndConditiondata: [],
      termsAndConditionErrorData: "",
      openDialog: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.inValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
      else if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    } else if (message.id === getName(MessageEnum.TermsConditionMessage)) {
      this.setState({ openDialog: message.getData(getName(MessageEnum.TermsConditionMessageData)) })
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    this.getTermsAndConditionData();
  }

  isValidResponse = (responseJson: { data: object }) => {
    return responseJson && responseJson.data;
  };

  inValidResponse = (responseJson: { errors: object }) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: TermsAndConditionTypes) => {
    if (apiRequestCallId === this.termsAndConditionApiCallId) {
      this.termsAndConditionSuccessCallBack(responseJson);
    }
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: string) => {
    if (apiRequestCallId === this.termsAndConditionApiCallId) {
      this.termsAndConditionFailureCallBack(responseJson);
    }
  };

  termsApiCallBody = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        configJSON.stringfy(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getTermsAndConditionData = async () => {
    this.termsAndConditionApiCallId = await this.termsApiCallBody({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.termsAndConditionEndpoint,
    });
  };

  termsAndConditionSuccessCallBack = (response: TermsAndConditionTypes) => {
    this.setState({
      termsAndConditiondata: response?.data,
    });
  };

  termsAndConditionFailureCallBack = (response: string) => {
    this.setState({
      termsAndConditionErrorData: response,
    });
  };

  handleClose = () => {
    this.setState({ openDialog: false })
  }
  // Customizable Area End
}
