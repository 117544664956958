import React from "react";
import { Box, Button, Chip, Grid, Typography } from "@material-ui/core"
import {CancelOutlined} from "@material-ui/icons"
import { configJSON } from "../../../blocks/ElasticSearch/src/ElasticSearchController.web";
import { bigCloudIcon, sadIcon, sampleImag, searchIcon, smallCloudIcon, starIcon } from "../../../blocks/ElasticSearch/src/assets"
import ShowComponent from "../utils/ShowComponent";
import Loader from "../Loader.web"
import { HotelCollectionType, HotelCollectionTypeKeys } from '../../../blocks/catalogue/src/OurHotelsController.web';
import { RatingType, starRatingMap } from "../../../blocks/catalogue/src/CatalogueController.web";
interface Props {
    classes: {[key:string]: string}
    loading: boolean
    data: HotelCollectionType[] | null
    onClose?: () => void
    onViewDetails: (id: string) => void
    view?: "FULL_PAGE" | "HALF_PAGE" 
}

const {translate} = configJSON
export default function SearchContent({data, loading, onClose, onViewDetails, classes, view}: Readonly<Props>) {
  const length = data?.length ?? 0
  const lang = localStorage?.getItem('lang') ?? 'en'
  const keyType = lang === 'en' ? '': 'arabic_'
  const noSearchFound =() => {
    return (
      <>
      <Grid item container direction='row' justifyContent='center' style={webStyle.mt}>
        <Box position={'relative'} right={'1.87rem'}>
          <img src={bigCloudIcon} alt='big cloud'/>
        </Box>
        <Box>
          <img src={sadIcon} alt= 'sadIcon' />
        </Box>
        <Box position={'relative'} left={'1.7rem'} alignSelf={'flex-end'}>
          <img src={smallCloudIcon} alt='small cloud' />
        </Box>
      </Grid>
      <Box mt={'1.25rem'} mb={'0.65rem'}>
        <Typography align='center' className='h5-font'>{translate.OOPS_NO_RES_FOUND[lang]}</Typography>
      </Box>
      <Box mb={'2.5rem'}>
        <Typography align='center' className='body2-font'>{translate.PLS_AN_SEARCH[lang]}</Typography>
      </Box>
      </>
    )
  }
  const getNoResultText = {
    en: <Typography className='subtitle1-font'><span className='elastic-span'>“No results found”</span> matching with your requirements.</Typography>,
    ar: <Typography className='subtitle1-font'><span className='elastic-span'>لم يتم العثور على نتيجة</span>.مطابقة لمتطلباتك</Typography>
     
  }

  const getResultText = {
    en: <Typography className='subtitle1-font'>Here {length > 1 ?"are":"is"} the <span className='elastic-span'>“{`${length}`} {length > 1 ? "Hotels": "Hotel"}”</span> matching with your requirements.</Typography>,
    ar: <Typography className='subtitle1-font'>مطابقة لمتطلباتك <span className='elastic-span'>“{`فنادق ${length}`}”</span> فيما يلي</Typography>,
  }

    return (
        <Grid item container direction ='column' wrap='nowrap' className={classes.container}>
          <ShowComponent show={loading}>
            <Box my={'1.5rem'}>
              <Loader loading />
            </Box>
          </ShowComponent>
          <ShowComponent show={!loading}>
            <>
            <ShowComponent show={!!onClose}>
              <Box alignSelf={'flex-end'} mb={'0.37rem'}>
                <CancelOutlined data-testid="search-dialog-cross" style={webStyle.point} htmlColor='#646464' opacity={'0.5 '} classes={{root: 'big-cancel'}} onClick={onClose}/>
              </Box>
            </ShowComponent>
          <Grid item container wrap='nowrap' direction={'row'} alignItems='center'>
            <Box ml={length ? '2.5rem' : '1.5rem'}>
              <img src={searchIcon} />
            </Box>
            <Box ml={'0.75rem'}>
              <Typography className='h6-font'>{translate.SEARCH_RESULTS[lang]}</Typography>
            </Box>
            <Box ml={'1rem'}>
              <Chip classes={{root: 'label-result-chip'}}label={!length ? translate.NO_RES_FOUND[lang]:`${length} ${translate.RES_FOUND[lang]}`} />
            </Box>
          </Grid>  
          <Box mt={'0.56rem'} ml={length ? '4.8rem': '3.8rem'}>
            <ShowComponent show={!length}>
              {getNoResultText[lang as 'en' | 'ar']}
            </ShowComponent>
            <ShowComponent show={!!length}>
              {getResultText[lang as 'en' | 'ar']}
            </ShowComponent>
          </Box>
          <ShowComponent show={!!length}>
            <Box className="search-results-grid-container">
            {
              data?.map((hotel, idx) => {
                const image = hotel.attributes.images?.[0] ?? sampleImag
                const starRating = hotel.attributes.star_rating
                const name = hotel.attributes[`${keyType}name` as HotelCollectionTypeKeys]
                const desc = hotel.attributes[`${keyType}description1` as HotelCollectionTypeKeys]
                return (
                  <Grid item container direction='row' wrap='nowrap'alignItems='center' className='search-results-grid' key={hotel.id}>
                    <Box mr={'0.5rem'}>
                      <Typography className='h4-font'>{idx <= 8 ? `0${idx+1}`: idx + 1}</Typography>
                    </Box>
                    <Box mr={'1.25rem'} display={['none','', 'block']} width={'7.935rem'} height={'10rem'}>
                      <img src={image} alt='sample-1'style={webStyle.img}/>
                    </Box>
                    <Box width={'45%'}>
                      <ShowComponent show={!!starRating}>
                      <Box>
                        {
                          Array(starRatingMap[starRating as RatingType]).fill(<img src={starIcon} alt='star' style={webStyle.mr}/>)
                        }
                      </Box>
                      </ShowComponent>
                      <Box mt={'0.5rem'} mb={'0.75rem'}>
                        <Typography className='subtitle2-font'>{name}</Typography>
                      </Box>
                      <Box>
                        <Typography className='body1-font'> 
                            {desc}
                        </Typography>
                      </Box>
                    </Box>
                    <Button classes={{
                      root: 'elastic-button-root'
                    }}
                    data-testid='view-details-button' 
                    onClick={() => {
                      onViewDetails(hotel.id)
                    }}>{translate.VIEW_DETAILS[lang]}</Button>
                  </Grid>
                )
              })
            }
            </Box>
          </ShowComponent>
          <ShowComponent show={!length}>
            {noSearchFound()}
          </ShowComponent>
            </>
          </ShowComponent>
                
        </Grid>
    )
}

SearchContent.defaultProps ={
  onClose: false,
  view: "FULL_PAGE"
}

const webStyle: {
  [key:string]: {
    [key:string]: string
  }
} = {
  mr: {
    marginRight: '0.25rem'
  },
  mt: {
    marginTop: '2.38rem',
    marginLeft: '-0.75rem'
  },
  point: {
    cursor: 'pointer'
  },
  img: {
    borderRadius: '0.3125rem',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}