import React, { ReactElement } from 'react';
import {MenuList, ClickAwayListener,Backdrop, Popover, MenuItem, ListItemIcon,ListItemText, Box} from "@material-ui/core";
import { SubMenuType } from '../../../blocks/landingpage/src/NavigationBarController.web';
import ShowComponent from '../utils/ShowComponent';
import './styles.css';
import Loader from '../Loader.web';
interface Props {
    children: ReactElement
    anchorEl: Element | null
    data_testId: string
    menuItems: Array<SubMenuType> | undefined
    handleClose: () => void
    onMenuItemClick: (type:string) => void
}

type PickCategoryKeys = keyof Pick<SubMenuType['attributes'], "arabic_name"| "name">

export default function CustomPopover({children,onMenuItemClick,anchorEl, handleClose, data_testId, menuItems}: Readonly<Props>) {
  const lang = localStorage.getItem('lang') ?? 'en'
  const keyType = lang === 'en' ? '': 'arabic_'
    return (
      <ClickAwayListener onClickAway={() => {
          handleClose()
        }}>
        <>
        {children}
        <Backdrop open={!!anchorEl} classes={{
          root: 'backdrop-root'
        }}>
        <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={() => {
          handleClose()
        }} anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        transformOrigin = {{
          horizontal: 'center',
          vertical: 'top'
        }}
        PaperProps={{
          style: {
            padding: '0 6px',
            borderRadius: '5px',
            width: '230px',
          },
        }}
        data-testid = {data_testId}
        >
          <ShowComponent show={Array.isArray(menuItems)}>
            <MenuList>
                {
                    menuItems?.map((item, idx) => {
                        const {attributes: {category_type, icon_url, id, ...rest}} = item
                        const actName = rest[`${keyType}name` as PickCategoryKeys] as string
                        return (
                            <MenuItem disableGutters key={id} onClick={() => {
                                onMenuItemClick(category_type);
                                handleClose()
                            }} 
                            classes={{
                              root: 'custom-popover-list-item'
                            }}
                            >
                                <ListItemIcon style={{minWidth: 'max-content', marginLeft: '17px'}}>
                                <img src={icon_url} alt={`icon_${id}`} style={{width: '1.125rem', height: '1.125rem'}}/>
                                </ListItemIcon>
                                <ListItemText style={{marginLeft: '8px'}} classes={{
                                  root: 'custom-popover-text'
                                }} disableTypography>{actName}</ListItemText>
                            </MenuItem>
                        )
                    })
                }
            </MenuList>
          </ShowComponent>
          <ShowComponent show={!Array.isArray(menuItems)}>
            <Box height={'5.6875rem'}>
              <Loader loading/>
            </Box>
          </ShowComponent>
        </Popover>
        </Backdrop>
        </>
      </ClickAwayListener>
    )
}