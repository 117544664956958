// Customizable Area Start
export const DownArrow = require("../assets/vectorDown.png");
export const Flag = require("../assets/ellipseflag.png");
export const logo = require("../assets/irislogo.png");
export const img1 = require("../assets/rectangle24.png");
export const img2 = require("../assets/island1.png");
export const Image1 = require("../assets/rectangle90.png");
export const Image2 = require("../assets/rectangle91.png");
export const Image3 = require("../assets/rectangle100.png");
export const Image4 = require("../assets/rectangle93.png");
export const Image5 = require("../assets/rectangle94.png");
export const History = require("../assets/vector1.svg");
export const Location = require("../assets/vector2.svg");

export const LocationIcon = require("../assets/TrandingHotel/locationIcon.png");
export const Fork = require("../assets/TrandingHotel/fork.png");
export const Doublebed = require("../assets/TrandingHotel/double-bed.png");
export const Meditation = require("../assets/TrandingHotel/icons8-meditation.png");
export const Pool = require("../assets/TrandingHotel/icons8-pool.png");
export const Gym = require("../assets/TrandingHotel/iconsgym.png");
export const TrandingBigimg = require("../assets/TrandingHotel/RectangleTrandingbig.png");

export const main1 = require("../assets/rectangle110.png");
export const main2 = require("../assets/rectangle111.png");
export const main3 = require("../assets/rectangle115.png");
export const Spa = require("../assets/spa-icons.png");
export const Bar = require("../assets/bar-icons.png");
export const Bathtub = require("../assets/bathtub.png");
export const Resturent = require("../assets/icons8-restaurant.png");
export const slogan = require("../assets/slogan3.png");
export const logoFooter = require("../assets/logoFooter.png");
export const contour = require("../assets/contour.png");
export const arrow = require("../assets/arrow.svg");
export const hotel = require("../assets/icons8-hotel-96 1.svg")
export const calendar = require("../assets/icons8-calendar-96 1.svg")
export const Hello = require("../assets/hello.png")
export const FooterIrisSlogon = require("../assets/slogan.svg")
export const IrisLogo = require("../assets/IRISLogo.svg")
export const download = require("../assets/download.svg");
export const file = require("../assets/file.svg")
export const about1 = require("../assets/about1.png");
export const about2 = require("../assets/about2.png");
export const about3A = require("../assets/about3A.png");
export const about3B = require("../assets/about3B.png");
export const about4 = require("../assets/about4.png");
export const About5 = require("../assets/about5.png");
export const ourvision = require("../assets/ourvisionLast1.png");
export const ourvisionCA1 = require("../assets/ourvisionCA1.png");
export const ourvisionCA2 = require("../assets/ourvisionCA2.png");
export const ourvisionCA3 = require("../assets/ourvisionCA3.png");
export const ourvisionLong1 = require("../assets/ourvisionLong1.png");
export const ourvisionLong2 = require("../assets/ourvisionLong2.png");
export const ourvisionCB1 = require("../assets/ourvisionCB1.png");
export const ourvisionCB2 = require("../assets/ourvisionCB2.png");
export const ourvisionLast1 = require("../assets/ourvisionLast1.png");
export const OurmissionLong1 = require("../assets/OurmissionLong1.png");
export const OurmissionLong2 = require("../assets/OurmissionLong2.png");
export const OurmissionBGdesiner = require("../assets/OurmissionBGdesiner.png");

export const arabicFlag = require("../assets/saudi-arabia-flag-icon.svg")
export const roundArrow = require("../assets/Group-79.svg")
// Customizable Area End