import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {  RefObject, createRef } from "react";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { HotelCollectionType } from "../../catalogue/src/OurHotelsController.web";
import * as H from 'history';
import {message as MESSAGE } from "antd";
import { match } from 'react-router-dom';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History
  location: H.Location
  match: match<{name:string}>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchInput: string
  dialogOpen: boolean
  searchResponse: {data: HotelCollectionType[]|null, loading: boolean}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchInputController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef:RefObject<HTMLInputElement>;
  searchApiCallid = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.inputRef = createRef<HTMLInputElement>()
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      dialogOpen: false,
      searchInput: '',
      searchResponse: {data: null, loading: false}

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    if(this.inputRef.current) {
      const {pathname} = this.props.location
    this.inputRef.current.value = pathname.startsWith("/search/") ? decodeURI(pathname.split("/")[2]): ""
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const {searchInput} = this.state;
    const {location: {pathname}} = this.props;
    if(prevState.searchInput !== searchInput) {
      if(!searchInput) {
        if(this.inputRef.current)
          this.inputRef.current.value = ''
        this.setState({dialogOpen: false})
      }else {
        this.handleSearchCall(searchInput)
      }
    }else if(!pathname.startsWith("/search/") && prevProps.location.pathname !== pathname) {
      if(this.inputRef.current) {
        this.setState(prevState => ({...prevState, searchInput: ''}))
      }
    }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (responseJson?.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isValidResponse<HotelCollectionType|null>(responseJson)) {
          if(apiRequestCallId === this.searchApiCallid) {
            this.setState(prevState => ({...prevState, searchResponse: {...prevState.searchResponse, data: responseJson.data, loading: false}})) 
          }
        } else if (this.isInValidResponse(responseJson)) {
          if(apiRequestCallId === this.searchApiCallid) {
            this.setState({searchResponse: {data: [], loading: false}})
          }
        }
    }
    // Customizable Area End
  }

  // web events

  // Customizable Area Start

  isValidResponse = <T,>(responseJson: {data: T}):boolean => {
    return !!responseJson?.data;
  };

  isInValidResponse = (responseJson: {error?: string}) => {
    return !!responseJson.error;
  };
  handleSearchInput = (value: string) => {
    this.setState({searchInput: value.trim()})
  }

  debounceSearchInput = configJSON.debounce(this.handleSearchInput)
  
  handleClose = () => {
    this.setState({dialogOpen: false})
  }
  handleSearchCall = (search: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchApiCallid = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchApiEndpoint(search)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      { 'Content-Type': configJSON.validationApiContentType }
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState(prev => ({...prev, searchResponse: {...prev.searchResponse, loading: true}, dialogOpen: true}))
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRedirection = () => {
    this.handleClose()
    this.props.history.replace(`/search/${this.inputRef.current?.value}`)
  }

  handleViewDetails = (id:string) => {
    this.props.history.push(`/hotels/${id}`);
    this.handleClose()
    this.handleSearchInput('')
  }
  clearInput = () => {
    if(this.inputRef.current) {
        this.inputRef.current.value = ""
        this.inputRef.current.focus()
    }
    this.handleSearchInput("")
  }

  handleKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if(e.keyCode == 13 && !!this.inputRef.current?.value.trim()) {
      this.handleRedirection()
    }
  }
  handleSearchClick = () => {
    if(this.inputRef.current?.value.trim()) {
      this.handleRedirection()
    }
  }
  // Customizable Area End
}
