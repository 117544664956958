export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// Customizable Area Start
export const vectorserch1 = require("../assets/vectorserch1.png");
export const vector = require("../assets/vector.png");
export const searchIcon = require('../assets/Frame(5).svg')
export const starIcon = require("../assets/star 1.svg")
export const sampleImag = require("../assets/Rectangle 297.png")
export const bigCloudIcon = require("../assets/Vector(1).svg")
export const smallCloudIcon = require("../assets/Vector(2).svg")
export const sadIcon = require("../assets/sad 1.svg")
// Customizable Area End