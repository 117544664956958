import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { notification, message as MESSAGE } from "antd";
import { runEngine } from "../../../framework/src/RunEngine";
import Slider from "react-slick";
import { air_condition, bath, blanket, cocktails, emailIcon, kettle, profileicon, telephone, tv, vault } from "./assets";

import { AmenityType, HotelCollectionType } from "./OurHotelsController.web";
import { SectionHotelsInterface, landingdataInterface } from "../../landingpage/src/Interfaces/LandingInterface";
import {match} from "react-router-dom";
import * as H from "history";
import { getApiCalls } from "./utils";

// Customizable Area Start


export const configJSON = require("./config");

const {hotelDetailsApiEndPoint, apiMethodTypeGet, productApiContentType, postApiMethod} = configJSON

export const amenitiesItems = [{
  text: 'Air - Conditioning',
  arabic_text: 'تكيف',
  icon: air_condition
},
{
  text: 'Kettle',
  arabic_text: 'غلاية',
  icon: kettle
},
{
  text: 'Mini-Bar',
  arabic_text: 'ميني بار',
  icon: cocktails
},
{
  text: 'Private Vault',
  arabic_text: 'قبو خاص',
  icon: vault
},
{
  text: 'Smart Television',
  arabic_text: 'التلفاز الذكي',
  icon: tv
},
{
  text: 'Private Bathroom',
  arabic_text: 'حمام خاص',
  icon: bath
},
{
  text: 'Blanket & Linen',
  arabic_text: 'بطانية وكتان',
  icon: blanket
}]

export const transformAmenitiesItems = () => {
  let obj: {[key:string]: string[]} = {}
  for(const item of amenitiesItems) {
    obj[item.text] =  [item.icon, item.arabic_text]
  }
  return obj;
}

export const contactList = [{
  img: profileicon,
  Head: "Contact Person",
  key: 'contact_person',
  arabic_key: 'arabic_contact_person'

}, {
  img: emailIcon,
  Head: "E-Mail Address",
  key: 'email_address',
  arabic_key: 'email_address',
}, {
  img: telephone,
  Head: "Telephone",
  key: 'telephone',
  arabic_key: 'telephone',
}]

export type ContactKeyType = 'contact_person' | 'email_address' | 'telephone'

export type PackageCategory = 'best_destination' | 'best_hotels' |'favourite_destination'
export interface Props {
  // Customizable Area Start
  history: H.History
  location: H.Location
  match: match<{id?:string}>
  // Customizable Area End
}

export interface RoomsTariffs {
  id:number
  description: string
  arabic_description: string
  currency: string
  arabic_currency: string
  price: string
}

export type RatingType = 'one_star' | 'two_star' | 'three_star' | 'four_star' |'five_star'

export interface PlanType {
  id: number
  no_of_persons: number
  per_amount: number;
  currency: string;
  arabic_currency: string;
}

export interface DetailsType {
  id: string
  type: string
  attributes: {
    product_name: string,
    arabic_product_name: string,
    description_one: string;
    arabic_description_one: string;
    description_two: string;
    arabic_description_two: string;
    catalogue_star_rating: RatingType | null
    images: string[] | null
    room_tarrifs: Array<RoomsTariffs>
    contact_person: string
    arabic_contact_person: string
    email_address: string
    telephone: string
    amenities: AmenityType[] | null
    select_plans: PlanType[] | null
    catalogue_reviews: string | null
    city_location_type: string | null
    arabic_city_location_type: string | null
  }
}

export type DetailsTypeKeys = keyof DetailsType['attributes']

export const starRatingMap = {
  one_star: 1,
  two_star: 2,
  three_star: 3,
  four_star: 4,
  five_star: 5
}

export interface HolidayPageType {
  id: string
  type: string
  attributes: {
    id: number
    title: string
    arabic_title: string
    arabic_description: string
    description: string
    image: string
    aminities:string
    clickedCardIndex: number
  }
}

export type HolidayPageTypeKeys = keyof HolidayPageType['attributes']
export interface S {
  // Customizable Area Start
  nav1: Slider | undefined
  nav2: Slider | undefined
  hotelDetails: {data:null | DetailsType, isLoading: boolean },
  holidayPage: {data: null | HolidayPageType[], isLoading: boolean}
  selectPlan: null | PlanType
  packages:{[key:string]: Array<HotelCollectionType> | null}
  promotionPage: {data:null | HolidayPageType[], isLoading: boolean}
  upcomingHotels: {data: null |Array<HotelCollectionType>, isLoading: boolean}
  upcomingHotelsPage: {data: any | landingdataInterface['data'][0], isLoading: boolean}
  clickedCardIndex :any
  descriptionValue :string | null
  currency:string | null
  price:number | null
  // Customizable Area End
}

interface SS {
  id: string;
}
// Customizable Area End

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  slider1 = undefined as unknown as Slider
  slider2 = undefined as unknown as Slider
  hotelDetailsApiId: string = '';
  holidayPageCallId: string = '';
  holidayBookApiCallId:string = '';
  packageCategoryApiCallId = {
    best_destination: '',
    best_hotels: '',
    favourite_destination: ''
  }
  promotionPageCallId = ''
  hotelCategoriesApiCallId = ''
  upcomingHotelApiCallId = ''
  contentManagementCallId = ''
  clickedCardIndex = ''
  descriptionValue = ''
  currency=''
  price=''
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    this.state = {
      nav1: this.slider1,
      nav2: this.slider2,
      hotelDetails: {data: null, isLoading: false},
      holidayPage: {data: null, isLoading: false},
      selectPlan: null,
      packages: {},
      promotionPage: {data: null, isLoading: false},
      upcomingHotels: {data: null, isLoading: false},
      upcomingHotelsPage: {data: null, isLoading: false},
      clickedCardIndex: null,
      descriptionValue:null,
      currency:null,
      price:null,

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    const {match} = this.props;
    if(match.path === '/hotels/:id') {
      this.setState(prev => ({...prev, hotelDetails: {...prev.hotelDetails, isLoading: true}}))
      this.getHotelDetails(match.params?.id)
    }else if(match.path === '/holidays') {
      this.setState(prevSate => ({...prevSate, holidayPage: {...prevSate.holidayPage, isLoading: true}}))
      await Promise.all([this.getCatalogueByPackageCategory('best_destination'), this.getCatalogueByPackageCategory('best_hotels'), this.getCatalogueByPackageCategory('favourite_destination')])
      this.getIrisHolidayPage()
    }else if(['/holidays/:id', '/promotions/:id'].includes(match.path)) {
      this.setState(prev => ({...prev, hotelDetails: {...prev.hotelDetails, isLoading: true}}))
      this.getCatalogueByPackageCategory('best_hotels')
      this.getHotelDetails(match.params?.id)
    }else if(match.path === '/promotions') {
      this.setState(prevState => ({...prevState, promotionPage: {...prevState.promotionPage, isLoading: true}}))
      await Promise.all([this.getCatalogueByPackageCategory('best_destination'), this.getCatalogueByPackageCategory('best_hotels'), this.getCatalogueByPackageCategory('favourite_destination')])
      this.getPromotionPage()
    }else if(match.path === '/upcoming-hotels') {
      this.getContentManagement()
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
    if(prevProps.match.path === '/promotions' && this.props.match.path === '/holidays') {
      this.setState(prevSate => ({...prevSate, holidayPage: {...prevSate.holidayPage, isLoading: true}}))
      await Promise.all([this.getCatalogueByPackageCategory('best_destination'), this.getCatalogueByPackageCategory('best_hotels'), this.getCatalogueByPackageCategory('favourite_destination')])
      this.getIrisHolidayPage()
    } else if(prevProps.match.path === '/holidays' && this.props.match.path === '/promotions') {
      this.setState(prevState => ({...prevState, promotionPage: {...prevState.promotionPage, isLoading: true}}))
      await Promise.all([this.getCatalogueByPackageCategory('best_destination'), this.getCatalogueByPackageCategory('best_hotels'), this.getCatalogueByPackageCategory('favourite_destination')])
      this.getPromotionPage()
    }else if(this.props.match.path === "/hotels/:id" && this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(prev => ({...prev, hotelDetails: {...prev.hotelDetails, isLoading: true}}))
      this.getHotelDetails(this.props.match.params.id)
    }else if(['/holidays/:id', '/promotions/:id'].includes(this.props.match.path) && this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(prev => ({...prev, hotelDetails: {...prev.hotelDetails, isLoading: true}}))
      this.getCatalogueByPackageCategory('best_hotels')
      this.getHotelDetails(this.props.match.params.id)
    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (responseJson?.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isSignupValidResponse<DetailsType>(responseJson)) {
        this.successCallBack(apiRequestCallId, responseJson?.data)
      } else if (this.isSignupInValidResponse(responseJson)) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  successCallBack = (apiRequestCallId: string , data: DetailsType | HolidayPageType[] | HotelCollectionType[] | SectionHotelsInterface['data'] | landingdataInterface['data'][0]) => {
    if(apiRequestCallId === this.hotelDetailsApiId) {
      if(data) {
        this.setState(prevState =>{
          const _data = data as DetailsType;
          const selectPlan = _data?.attributes?.select_plans?.[0] ?? null
          return {...prevState, hotelDetails:{...prevState.hotelDetails, data: _data, isLoading: false}, selectPlan}
        })
      }else {
        this.setState(prev => ({...prev, hotelDetails: {...prev.hotelDetails, isLoading: false, data: null}}))
      }
    }else if(apiRequestCallId === this.holidayPageCallId) {
      this.setState(prevState => ({...prevState, holidayPage: {...prevState.holidayPage, data: data as HolidayPageType[], isLoading: false}}))
    }else if(apiRequestCallId === this.packageCategoryApiCallId.best_destination) {
      this.setState(prevState => ({...prevState, packages: {...prevState.packages,best_destination: data as HotelCollectionType[] }}))
    }else if(apiRequestCallId === this.packageCategoryApiCallId.favourite_destination) {
      this.setState(prevState => ({...prevState, packages: {...prevState.packages,favourite_destination: data as HotelCollectionType[]}}))
    }else if(apiRequestCallId === this.packageCategoryApiCallId.best_hotels) {
      this.setState(prevState => ({...prevState, packages: {...prevState.packages,best_hotels: data as HotelCollectionType[]}}))
    }else if(apiRequestCallId === this.promotionPageCallId) {
      this.setState(prevState => ({...prevState, promotionPage: {...prevState.promotionPage, data: data as HolidayPageType[], isLoading: false}}))
    }else if(apiRequestCallId === this.contentManagementCallId) {
      this.setState(prev => ({...prev, upcomingHotelsPage: {...prev.upcomingHotelsPage, data: data as landingdataInterface['data'][0]}}), () => {
        this.getHotelCategories()
      })
    } else if(apiRequestCallId === this.hotelCategoriesApiCallId) {
      const categories = data as SectionHotelsInterface['data']; 
      const foundCategory = categories.find(category => category.attributes.category_type === configJSON.ourUpcomingHotels)
      if(foundCategory) {
        this.getUpcomingHotels(foundCategory.id.toString())
      }
    }else if(apiRequestCallId === this.upcomingHotelApiCallId) {
      this.setState(prevSate => ({...prevSate, upcomingHotels: {...prevSate.upcomingHotels, isLoading: false, data: data as HotelCollectionType[]}, upcomingHotelsPage: {...prevSate.upcomingHotelsPage, isLoading: false}}))
    }
   
  }

  isSignupValidResponse = <T,>(responseJson: {data?: T}):boolean => {
      return !!responseJson?.data;
  };

  isSignupInValidResponse = (responseJson: {errors?: string[]}) => {
    return !!responseJson?.errors;
  };

  getHotelDetails = (id:string|undefined) => {
    if(typeof id === "string") {
      this.hotelDetailsApiId = getApiCalls(hotelDetailsApiEndPoint, () => {}, id)
    }
  }

  getIrisHolidayPage = () => {
    this.holidayPageCallId = getApiCalls(configJSON.holidayPageEndpoint, () => {})
  }

  handleSelectPlan = (plan: PlanType) => {
    this.setState({selectPlan: plan})
  }

  getCatalogueByPackageCategory = (category:PackageCategory) => {
    this.packageCategoryApiCallId[category] = getApiCalls(configJSON.filterPackageCatEndpoint,() => {}, category)
  }

  getPromotionPage = () => {
    this.promotionPageCallId = getApiCalls(configJSON.promotionPageEndpoint, () => {})
  }

  getHotelCategories = () => {
    this.hotelCategoriesApiCallId = getApiCalls(configJSON.hotelCatgoriesEndpoint , () => {
      this.setState(prevState => ({...prevState, upcomingHotels: {...prevState.upcomingHotels, isLoading: true}}))
    })
  }
  getUpcomingHotels = (id: string) => {
    this.upcomingHotelApiCallId = getApiCalls(configJSON.ourHotelsApiEndPoint, () => {}, id);
  }

  getContentManagement = () => {
    this.contentManagementCallId = getApiCalls(configJSON.contentManagementEndpoint, () => {
      this.setState(prev => ({...prev, upcomingHotelsPage: {...prev.upcomingHotelsPage, isLoading: true}}))
    }, configJSON.ourUpcomingHotels)
  }

  handleFormHoiday = () => {
    const message = new Message(getName(MessageEnum.FormTypeMessage))
    const message2 = new Message(getName(MessageEnum.SelectedAdultMessage));
    const message3 = new Message(getName(MessageEnum.SelectedHotelMessage));
    const {data} = this.state.hotelDetails
    const catalogueData = {id: this.props.match.params.id, attributes: {name: data?.attributes?.product_name, arabic_name: data?.attributes?.arabic_product_name,description:'',price:'', currency:''  }}
    message.addData(getName(MessageEnum.FormTypeMessageData), "Holiday")
    message2.addData(getName(MessageEnum.SelectedAdultMessageData), this.state.selectPlan)
    message3.addData(getName(MessageEnum.SelectedHotelMessageData), [catalogueData])
    runEngine.sendMessage(message.id, message);
    runEngine.sendMessage(message2.id, message2)
    runEngine.sendMessage(message3.id, message3)
  }
  handleFormRoom = () => {
    const message = new Message(getName(MessageEnum.FormTypeMessage))
    const message2 = new Message(getName(MessageEnum.SelectedAdultMessage));
    const message3 = new Message(getName(MessageEnum.SelectedHotelMessage));
    const {data} = this.state.hotelDetails
    const RoomcatalogueData = {id: this.props.match.params.id, attributes: {name: data?.attributes?.product_name, arabic_name: data?.attributes?.arabic_product_name, description:this.state.descriptionValue, price:this.state.price, currency:this.state.currency }}
    message.addData(getName(MessageEnum.FormTypeMessageData), "Book")
    message2.addData(getName(MessageEnum.SelectedAdultMessageData), this.state.selectPlan)
    message3.addData(getName(MessageEnum.SelectedHotelMessageData), [RoomcatalogueData])
    runEngine.sendMessage(message.id, message);
    runEngine.sendMessage(message2.id, message2)
    runEngine.sendMessage(message3.id, message3)
  }

  handleCustomSliderRef = (key:keyof Pick<S, 'nav1'| 'nav2'>,ref: Slider) => {
    this.setState(prev => ({...prev,[key]: ref}))
  }
  cardHandleclick = (item:any,idx:number) => {
    const lang = localStorage.getItem('lang') ?? 'en';
    const keyType = lang === 'en' ? "": "arabic_"
    this.setState(prevState => ({
        clickedCardIndex: prevState.clickedCardIndex === idx ? null : idx,
        descriptionValue: prevState.clickedCardIndex === idx ? null : item[`${keyType}description`],
        currency: prevState.clickedCardIndex === idx ? null : item[`${keyType}currency`],
        price: prevState.clickedCardIndex === idx ? null : item[`${keyType}price`]
    }));
};

  // Customizable Area End
}
