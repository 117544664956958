import React from 'react';
import { Box, Typography, FormControl, InputLabel, Input, Select, MenuItem, Grid, InputProps, Chip} from "@material-ui/core"
import {KeyboardDatePicker} from "@material-ui/pickers"
import {KeyboardArrowDown} from '@material-ui/icons'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
export const warnIcon = require("./Vector(3).svg")
export const requiredIcon = require('./Frame(6).svg');
import './styles.css'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';


interface FormInputProps extends InputProps{
    label: string | React.ReactElement
    name: string
    items?: {[key:string]: string | number}[]
    toBeComponent: 'Select' | 'Input' | 'KeyboardDatePicker' | 'Chip',
    width?: Array<string | undefined> | string
    value?: unknown
    onChange: (e:React.ChangeEvent<{name?:string, value:unknown}>) => void
    errorLabel?: string | boolean
    required?: boolean
    minDate?:ParsableDate,
    maxDate?: ParsableDate
}
export default function FormInput({toBeComponent, label, name, items, width,value, onChange, errorLabel, required,minDate,maxDate,...rest}: Readonly<FormInputProps>){
    let Component;
    if(toBeComponent === 'Select')
    Component = <Select disableUnderline IconComponent={KeyboardArrowDown} name={name} onChange={onChange} value={value} {...rest}>
                    { !!items?.length &&
                        items.map(({value, text}) => (
                            <MenuItem value={value} key={value}>{text}</MenuItem>
                        ))
                    }
                </Select>
    else if(toBeComponent === 'Input') {
        if(rest.type === 'tel') {
            Component = <PhoneInput
            country={''}
            value={value as string | null | undefined}
            inputStyle={{
                border: 'none',
                backgroundColor: '#f6f6f6',
                height: '44px',
                color: '#676766',
                width: '100%',
                paddingLeft: '1.25rem'
            }}
            onChange={(value) => onChange({target:{name, value: value as unknown}} as React.ChangeEvent<{name:string|undefined, value:unknown}>)}
          />
        } else
        Component = <Input disableUnderline name={name} {...rest} onChange={onChange} value={value || ''}/>
    }
    else if(toBeComponent === 'KeyboardDatePicker')
    Component = <KeyboardDatePicker 
                    keyboardIcon={<KeyboardArrowDown />} 
                    variant='inline'
                    disableToolbar
                    disablePast
                    autoOk
                    format='DD-MM-yyyy'
                    onChange={(date, value) =>{
                        onChange({target: {name, value: !date ? date :new Date(date)}} as React.ChangeEvent<{name?:string, value:unknown}>)
                        
                        }}
                    minDate={minDate}
                    maxDate={maxDate} 
                    value={value as string || null}
                    name={name}
                />
    else if (toBeComponent === 'Chip')
    Component = <Grid item container direction='row'>
            {
               !!items?.length &&( items?.map(({value: ownValue, text}) => {
                    return (
                        <Chip className={`${value ===  ownValue ? 'chip select_chip': 'chip'}`} label={text} key={ownValue} onClick={(e) => onChange({target: {name, value: ownValue}} as React.ChangeEvent<{name:string|undefined, value:unknown}>)}/>
                    )
                })
                )
            }
        </Grid>
    return (
        <Box width={width} className='formInput-box'>
            <FormControl variant="standard" fullWidth>
                <InputLabel shrink={true}>{label}{required && <span className='required-span'><img src={requiredIcon} alt='*'/></span>}</InputLabel>
                {Component}
            </FormControl>
            {!!errorLabel && (
                 <Box display={'inline-flex'} mt={'0.5rem'} width={'100%'}>
                 <img src={warnIcon} alt='warn_icon' className='warn-icon'/>
                 <Typography className="warn-label">{errorLabel}</Typography>
               </Box>
            )}
        </Box>
    )
}

FormInput.defaultProps = {
    width: '47.5%',
    required: true,
    minDate: new Date("1900-01-01"),
    maxDate: new Date("2100-01-01")
}