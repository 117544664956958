import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { HotelCollectionType } from "../../catalogue/src/OurHotelsController.web";
import * as H from 'history';
import {message as MESSAGE, notification } from "antd";
import { match } from 'react-router-dom';

const getApiAndResponseJSON = (message: Message) => {
  return {
    responseJson : message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    ),
    apiRequestCallId: message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History
  location: H.Location
  match: match<{name:string}>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchData: {data: HotelCollectionType[]|null, loading: boolean}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ElasticSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchDataApiCallId = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchData: {data: null, loading: false}

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const {match} = this.props
    if(match.path === "/search/:name") {
      this.getSearchData(match.params.name)
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const {match} = this.props
    if(match.params.name !== prevProps.match.params.name) {
      this.getSearchData(match.params.name)
    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const {responseJson, apiRequestCallId} = getApiAndResponseJSON(message)
  
      if (responseJson?.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isValidResponse<HotelCollectionType|null>(responseJson)) {
          if(apiRequestCallId === this.searchDataApiCallId) {
            this.setState(prevState => ({...prevState, searchData: {...prevState.searchData, data: responseJson.data, loading: false}})) 
          }
        } else if (this.isInValidResponse(responseJson)) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // web events

  // Customizable Area Start

  getSearchData = (search: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchDataApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchApiEndpoint(search)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      { 'Content-Type': configJSON.validationApiContentType }
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState(prev => ({...prev, searchData: {...prev.searchData, loading: true}}))
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleViewDetails = (id:string) => {
    this.props.history.push(`/hotels/${id}`);
  }

  isValidResponse = <T,>(responseJson: {data: T}):boolean => {
    return !!responseJson?.data;
  };

  isInValidResponse = (responseJson: {errors?: string[]}) => {
    return !!responseJson?.errors;
  };

  // Customizable Area End
}
