Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions3";
exports.labelBodyText = "TermsAndConditions3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.termsAndConditionEndpoint = "bx_block_termsandconditions3/term_and_conditions";
exports.translate = {
  TERMS_CONDITION: {
    en: 'Terms & Conditions',
    ar: 'البنود و الظروف'
  },
  TERMS_CONDITION_BODY_1: {
    ar: 'مرحباً بكم في الموقع الإلكتروني لفندق إيريس. تحكم هذه الشروط والأحكام استخدامك لموقعنا الإلكتروني وخدماته. من خلال الوصول إلى موقعنا أو استخدامه، فإنك توافق على الالتزام بهذه الشروط والأحكام. يرجى قراءتها بعناية قبل المتابعة.',
    en: "Welcome to Iris Hotel's website. These terms and conditions govern your use of our website and its services. By accessing or using our website, you agree to be bound by these terms and conditions. Please read them carefully before proceeding."
  },
  TERMS_CONDITION_SUBTITLE_1: {
    ar: ':استخدام الموقع',
    en: 'Website Usage:'
  },
  TERMS_CONDITION_BODY_2: {
    ar: '.أ. يجب أن يكون عمرك 18 عامًا على الأقل أو أن تحصل على موافقة الوصي القانوني لاستخدام موقعنا',
    en: "a. You must be at least 18 years old or have the consent of a legal guardian to use our website."
  },
  TERMS_CONDITION_BODY_3: {
    ar: '.ب. أنت توافق على استخدام الموقع فقط للأغراض القانونية وبطريقة لا تنتهك أي قوانين أو لوائح معمول بها',
    en: 'b. You agree to use the website only for lawful purposes and in a manner that does not violate any applicable laws or regulations.'
  },
  TERMS_CONDITION_SUBTITLE_2: {
    en: 'Reservation payment',
    ar: 'دفع الحجز'
  },
  TERMS_CONDITION_BODY_4: {
    ar: '.أ. المعلومات المقدمة على موقعنا فيما يتعلق بتوفر الغرف والأسعار ووسائل الراحة عرضة للتغيير دون إشعار مسبق',
    en: 'a. The information provided on our website regarding room availability, rates, and amenities is subject to change without notice.'
  },
  TERMS_CONDITION_BODY_5: {
    ar: '.ب. تخضع الحجوزات التي تتم عبر موقعنا الإلكتروني لمدى التوفر',
    en: 'b. Reservations made through our website are subject to availability.'
  },
  TERMS_CONDITION_BODY_6: {
    en: 'c. You agree to provide accurate and complete information when making a reservation.',
    ar: '.ج. أنت توافق على تقديم معلومات دقيقة وكاملة عند إجراء الحجز'
  }
}
// Customizable Area End