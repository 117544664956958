import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Dialog, DialogTitle, Grid, Box, DialogContent, Typography, styled } from "@material-ui/core"
import { Close } from "@material-ui/icons"
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 770,
      lg: 1200,
      xl: 1536,
    }
  },
  overrides: {
    MuiDialogContent: {
      root: {
        position: 'relative',
        top: '-43px',
        paddingLeft: '60px',
        overflowY: 'hidden'
      }
    },
    MuiDialog: {
      paper: {
        overflow: 'hidden !important',
        borderRadius: '20px',
        top: "128px"
      }
    }
  },
  typography: {
    h1: {
      fontSize: '32px',
      lineHeight: '48px',
      fontWeight: 500
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#676766'
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 700,
    },
  },
});
// Customizable Area End

import TermsAndConditions3Controller, { configJSON } from "./TermsAndCondition3Controller.web";

const { translate } = configJSON
export default class TermsAndConditions3 extends TermsAndConditions3Controller {

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { openDialog } = this.state
    const lang = localStorage?.getItem('lang') || 'en'
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog open={openDialog} fullWidth maxWidth='md' onClose={this.handleClose} disableEscapeKeyDown scroll="body">
          <DialogTitle>
            <Grid container direction="row" justifyContent="flex-end" wrap="nowrap" alignItems="flex-start">
              <Box style={webStyle.semiCircle} />
              <Box onClick={this.handleClose} style={{ cursor: 'pointer' }}>
                <Close htmlColor="#676766" width={'24px'} height={'24px'} />
              </Box>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center" alignItems="center" style={webStyle.termsIconContainer}>
              <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Frame" clip-path="url(#clip0_416_1094)">
                  <path id="Vector" d="M5.25 3V1.5H12.75V3H15.0049C15.4164 3 15.75 3.33371 15.75 3.74505V15.7549C15.75 16.1664 15.4163 16.5 15.0049 16.5H2.99505C2.58357 16.5 2.25 16.1663 2.25 15.7549V3.74505C2.25 3.33357 2.58371 3 2.99505 3H5.25ZM5.25 4.5H3.75V15H14.25V4.5H12.75V6H5.25V4.5ZM6.75 3V4.5H11.25V3H6.75Z" fill='#fff' />
                </g>
                <defs>
                  <clipPath id="clip0_416_1094">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Grid>
            <Typography style={webStyle.headingMargin} variant="h1">{translate.TERMS_CONDITION[lang]}</Typography>
            <CustomTermCondition mt={'1rem'} >
              {this.state.termsAndConditiondata.map((item: { attributes: { description: string, arabic_description: string } }) => {
                return (
                  <Box mt={'0.5rem'}>
                    {lang == "ar" ? <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: `${item.attributes.arabic_description}` }}></div></Typography> :
                      <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: `${item.attributes.description}` }}></div></Typography>}
                  </Box>
                )
              })}
            </CustomTermCondition>
          </DialogContent>
          <Box style={webStyle.fullCircle} />
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [key: string]: string | number | unknown
  }
} = {
  semiCircle: {
    width: '167px',
    height: '83px',
    backgroundColor: '#603',
    opacity: 0.1,
    borderRadius: '0 0 10rem 10rem',
    position: 'relative',
    top: '-20px',
    marginRight: '95px'
  },
  termsIconContainer: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    background: '#603'
  },
  fullCircle: {
    width: '97px',
    height: '97px',
    borderRadius: '50%',
    backgroundColor: '#603',
    opacity: 0.1,
    position: 'absolute',
    left: '-48.5px',
    bottom: '-48.5px'
  },
  "headingMargin": {
    marginTop: "10px"
  }
};
const CustomTermCondition = styled(Box)({
  height: "390px",
  paddingRight: "5%",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "3px",
    background: "#ffffff",
    borderRadius: "50%"
  },
  " &::-webkit-scrollbar-thumb": {
    backgroundColor: "#660033 !important",
    borderRadius: "50px",
    width: "2px"
  },
  "&::-webkit-scrollbar-track": {
    background: "#b3b3b3",
    borderRadius: "50px",
    borderLeft: "1px solid rgb(246, 246, 246)",
    borderRight: "1px solid rgb(246, 246, 246)"
  },
  "@media (max-width: 1000px)": {
    height: "auto",
    padding: "0 2%"
  },
});
// Customizable Area End
