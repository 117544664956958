import React from "react";

import {
  Box,
  Button,
  Typography,
  Dialog,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FormInput from "../../../components/src/forminput/FormInput.web"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Customform3Controller, { catalogueFieldName, configJSON, FormContentKeys, FormContentType, FormType, title } from "./Customform3Controller.web";
import { calendarIcon, idea, semiCircleIcon } from "./assets";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { Overrides } from "@material-ui/core/styles/overrides";
import './styles.css';
import ShowComponent from "../../../components/src/utils/ShowComponent";
import Loader from "../../../components/src/Loader.web";

const {translate} = configJSON

const theme = createTheme({
  palette: {
      primary: {
          main: "#0000ff",
          contrastText: "#fff",
        },
  },
  breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 770,
        lg: 1200,
        xl: 1536,
      }
    },
  typography: {
      h6: {
        color: "#000",
        fontSize: "2rem",
        fontWeight: 500,
        lineHeight: "3rem",
        letterSpacing: '0'
      },
      subtitle1: {
        color: "#603",
        fontSize: "0.875rem",
        fontWeight: 600,
        lineHeight: "normal",
        letterSpacing: "0.25rem",
        textTransform: "uppercase"
      },
      subtitle2: {
          color: '#363636',
          fontSize: '1.5rem',
          fontWeight: 500,
          lineHeight: 'normal',
          letterSpacing: '0'
      },
      overline: {
          color: '#676766',
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.3125rem',
          letterSpacing: '0',
          textTransform: 'none'
      },
      caption: {
          color: '#363636',
          fontSize: '0.75rem',
          fontWeight: 600,
          lineHeight: '1.3125rem',
          letterSpacing: '0'
      },
      body1: {
        color: "#0F172A",
        fontSize: '0.875rem',
        fontWeight: 700,
        lineHeight: '1.375rem' /* 157.143% */,
        letterSpacing: '0'
      },
      body2: {
        fontSize: '0.75rem',
        lineHeight: 'normal',
        letterSpacing: '0',
        fontWeight: 'inherit'
      }
  },
  overrides: {
      MuiDialog: {
          paper: {
              borderRadius: '0.3125rem'
          }
      },
      MuiInputLabel : {
          formControl: {
              top: '-20px',
              color: '#363636 !important',
              fontSize: '0.75rem',
              fontWeight: 600,
              lineHeight: '1.3125rem'
          },
          shrink: {
            transform: 'translate(0,-4px) !important'
          }
      },
      MuiInput: {
          formControl: {
              marginTop: '0 !important'
          },
          underline: {
              '&:after': {

                  borderBottom: 'none'
              },
              '&:before': {
                  borderBottom: 'none !important'
              },
              '&:hover': {
                  borderBottom: 'none'
              }
          }
      },
      MuiInputBase: {
          input: {
              height: 'max-content',
              padding: '0.69rem 0 0.75rem 1.25rem',
              color: '#676766'
          },
          root: {
              background: '#f6f6f6 !important',
              color: '#676766',
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.3125rem'
          },
          multiline:{
              padding: '0.69rem 1.25rem'
            }
      },
      MuiSelect: {
          icon: {
              right: '12px'
          }
      },
      MuiChip: {
          root: {
              margin: '0.5rem 0.5rem 0.5rem 0'
          }
      },
      MuiFormLabel: {
          asterisk: {
              color: '#D80000',
              fontSize: '0.75rem',
              position: 'relative',
              left: '0.5rem'
          }
      },
      MuiButton: {
        root: {
            letterSpacing: '0 !important'
        }
      },
      MuiPickersToolbar: {
        toolbar: {
            backgroundColor: "#603"
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: '#603',
          '&:hover': {
            backgroundColor: '#000',
          },
        },
        current: {
            color: '#0F172A',
            fontWeight: '700 !important'
        },
        day: {
            color: '#0F172A'
        }
      },
      MuiPickersYear: {
        yearSelected: {
            color: "#603"
        },
        root: {
            color: "#66003380",
            "&:focus": {
                color: "#603"
            }
        }
      },
      MuiMenuItem: {
        root: {
          color: '#676766',
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.3125rem'
        }
      }
  } as Overrides
})
// Customizable Area End

export default class Customform3 extends Customform3Controller {

  // Customizable Area Start
  template1 = () => {
    const {formType} = this.props;
    const lang = localStorage.getItem('lang') ?? 'en';
    const keyType = lang === 'en' ? "": "arabic_"
    const {dirty, formData, hotelCollections, formContent} = this.state
    const formContentData = formContent.data as FormContentType
    const hotelMenuItems = hotelCollections.map(item => ({value: item.id, text: this.handleTernary(lang === 'en', item.attributes.name, item.attributes.arabic_name)}))
    const menuItems = Array.from(new Array(11), (_, k) => ({value: k, text: k}))
    const adultMenuItems =  [...menuItems]
    const titleErrorLabel =  this.handleTernary(dirty.catalogue_id , translate.TITLE_REQ[lang], "")
    const fullnameErrorLabel = dirty.fullname ? this.handleTernary(!!formData.fullname, translate.NAME_INVALID[lang], translate.NAME_REQ[lang]): false
    const contactErrorLabel = dirty.contact ? this.handleTernary(!!formData.contact, translate.CONTACT_INVALID[lang], translate.CONTACT_REQ[lang]) : false
    const feedbackErrorLabel = dirty.feed_back ? this.handleTernary(!! formData.feed_back ,translate.FEED_INVALID[lang], translate.FEED_REQ[lang]): false
    const emailErrorLabel = dirty.email_address ? this.handleTernary(!!formData.email_address ,translate.EMAIL_INVALID[lang], translate.EMAIL_REQ[lang]): false
    const adultErrorLabel =  this.handleTernary(dirty.no_of_adults , translate.FIELD_REQ[lang], "")
    const kidErrorLabel =  this.handleTernary(dirty.no_of_kids, translate.FIELD_REQ[lang], "")
    const startDateErrorLabel =  this.handleTernary(dirty.start_date , translate.FIELD_REQ[lang], "")
    const endDateErrorLabel =  this.handleTernary(dirty.end_date , translate.FIELD_REQ[lang], "")
    return (
      <Grid item container direction='column' className='right-container-form'>
            <Grid item container direction='row' wrap='nowrap'>
                <FormInput onChange={this.handleChange} errorLabel={titleErrorLabel} width={'50%'} name='catalogue_id' value={formData.catalogue_id} label={this.handleEmpty(formContentData[`${keyType}label_name1` as FormContentKeys], catalogueFieldName[formType as FormType](lang))} toBeComponent='Select' items={hotelMenuItems} />
                <FormInput onChange={this.handleChange} errorLabel={fullnameErrorLabel} width={'50%'} name='fullname' value={formData.fullname}label={this.handleEmpty(formContentData[`${keyType}label_name2` as FormContentKeys], translate.FULL_NAME[lang])} toBeComponent='Input' type='text' />
            </Grid>
            <Grid item container direction='row' wrap='nowrap'>
                <FormInput onChange={this.handleChange} errorLabel={startDateErrorLabel} width={'50%'} name='start_date' value={formData.start_date ?? ''} maxDate={formData?.end_date as ParsableDate ?? new Date("2100-01-01")} label={<>{this.handleEmpty(formContentData[`${keyType}label_name3` as FormContentKeys], translate.START_DATE[lang])} <img src={calendarIcon} className="calendar-icon" alt={"calendar_icon"}/></>} toBeComponent='KeyboardDatePicker'/>
                <FormInput onChange={this.handleChange} errorLabel={endDateErrorLabel} width={'50%'} name='end_date' value={formData.end_date ?? ''} minDate={formData?.start_date as ParsableDate ?? new Date("1900-01-01")} label={<>{this.handleEmpty(formContentData[`${keyType}label_name4` as FormContentKeys], translate.END_DATE[lang])} <img src={calendarIcon} className="calendar-icon" alt={"calendar_icon"}/></>} toBeComponent='KeyboardDatePicker'/>
            </Grid>            
            <Grid item container direction='row' wrap='nowrap'>
                <FormInput onChange={this.handleChange} errorLabel={emailErrorLabel} width={'50%'} name='email_address' value={formData.email_address} label={this.handleEmpty(formContentData[`${keyType}label_name5` as FormContentKeys], translate.EMAIL_ADDR[lang])} toBeComponent='Input' type='email'/>
                <FormInput onChange={this.handleChange} errorLabel={contactErrorLabel} width={'50%'} name='contact' value={formData.contact} label={this.handleEmpty(formContentData[`${keyType}label_name6` as FormContentKeys], translate.CONTACT[lang])} toBeComponent='Input' type='tel'/>
            </Grid>            
            <Grid item container direction='row' wrap='nowrap'>
                <FormInput onChange={this.handleChange} errorLabel={adultErrorLabel} width={'50%'} name='no_of_adults' value={formData.no_of_adults}label={this.handleEmpty(formContentData[`${keyType}label_name7` as FormContentKeys], translate.NUM_ADULTS[lang])} toBeComponent='Select' items={adultMenuItems} />
                <FormInput onChange={this.handleChange} errorLabel={kidErrorLabel} width={'50%'} name='no_of_kids' value={formData.no_of_kids} label={this.handleEmpty(formContentData[`${keyType}label_name8` as FormContentKeys], translate.NUM_KIDS[lang])} toBeComponent='Select' items={menuItems} />
            </Grid>       
            <Box display='flex'>
                <FormInput onChange={this.handleChange} name='promo_code' required={false} value={formData.promo_code} label={this.handleEmpty(formContentData[`${keyType}label_name9` as FormContentKeys], translate.PROMO_CODE[lang])} toBeComponent='Input' type='text' width='100%' />
            </Box>    
            <Box display='flex'>
                <FormInput onChange={this.handleChange} errorLabel={feedbackErrorLabel} name='feed_back' value={formData.feed_back?.trimLeft()} label={this.handleEmpty(formContentData[`${keyType}label_name10` as FormContentKeys], translate.FEEDBACK[lang])} toBeComponent='Input' type='text' multiline={true} classes={{inputMultiline: 'input-multiline'}} placeholder = {translate.FEEDBACK_PLACEHOLDER[lang]} width='100%' rows={3}/>
            </Box>
        </Grid>
    )
  }

  template2 = () => {
    const {formType} = this.props;
    const {formData, dirty, formContent} = this.state
    const lang = localStorage.getItem('lang') ?? 'en';
    const keyType = lang === 'en' ? '': 'arabic_'
    const formContentData = formContent.data  as FormContentType
    const businessItems = [{value: 'hotel', text: translate.HOTELS[lang]},{value: 'restaurant', text: translate.RESTAURANT[lang]},{value: 'apartment', text: translate.APTMT[lang]}, {value: 'spa', text: translate.SPA[lang]}, {value: 'others', text: translate.OTHERS[lang]}]
    const interestItems = [{value: 'hotel management', text: translate.HOTEL_MGMT[lang]},{value: 'hotel operation', text: translate.HOTEL_OPN[lang]}, {value: 'consultation', text: translate.CONSULTATION[lang]}, {value: 'others', text: translate.OTHERS[lang]}]
    const titleItems = [{value: 'Mr', text: translate.MR[lang]}, {value: 'Mrs', text: translate.MRS[lang]}]
    const titleErrorLabel =  dirty.title ? translate.TITLE_REQ[lang]: false
    const fullnameErrorLabel = dirty.full_name ? this.handleTernary(!!formData.full_name, translate.NAME_INVALID[lang], translate.NAME_REQ[lang]): false
    const contactErrorLabel = dirty.phone_number ? this.handleTernary(!!formData.phone_number, translate.CONTACT_INVALID[lang], translate.CONTACT_REQ[lang]) : false
    const feedbackErrorLabel = dirty.description ? this.handleTernary(!! formData.description , translate.FEED_INVALID[lang], translate.FEED_REQ[lang]): false
    const emailErrorLabel = dirty.email ? this.handleTernary(!!formData.email ,translate.EMAIL_INVALID[lang], translate.EMAIL_REQ[lang]): false
    const businessTypeErrorLabel =  dirty.bussiness_type ? translate.FIELD_REQ[lang]: false
    const intServiceErrorLabel =  dirty.intrested_service ? translate.FIELD_REQ[lang]: false
    return (
        <Grid item container direction='column' className='right-container-form'>
            <Grid item container direction='row' wrap='nowrap'>
            <FormInput onChange={this.handleChange} errorLabel={titleErrorLabel} name='title' value={formData.title ?? ''} width={'50%'} label={this.handleEmpty(formContentData[`${keyType}label_name1` as FormContentKeys], catalogueFieldName[formType as FormType](lang))} toBeComponent='Select' items={titleItems} />
            <FormInput onChange={this.handleChange} errorLabel={fullnameErrorLabel} name='full_name' value={formData.full_name} width={'50%'} label={this.handleEmpty(formContentData[`${keyType}label_name2` as FormContentKeys], translate.FULL_NAME[lang])} toBeComponent='Input' type='text' />
            </Grid>
            <Grid item container direction='row' wrap='nowrap'>
            <FormInput onChange={this.handleChange} errorLabel={contactErrorLabel} name='phone_number' value={formData.phone_number} width={'50%'} label={this.handleEmpty(formContentData[`${keyType}label_name3` as FormContentKeys] ,translate.PHONE_NUM[lang])} toBeComponent='Input' type='tel'/>
            <FormInput onChange={this.handleChange} errorLabel={emailErrorLabel} name='email' value={formData.email} width={'50%'} label={this.handleEmpty(formContentData[`${keyType}label_name4` as FormContentKeys], translate.EMAIL[lang])} toBeComponent='Input' type='email'/>                
            </Grid>
            <Box display={'flex'}>
            <FormInput onChange={this.handleChange} name='website_or_businessname' required={false} value={formData.website_or_businessname} label={this.handleEmpty(formContentData[`${keyType}label_name5` as FormContentKeys], translate.CURR_WEB_EX[lang])} toBeComponent='Input' type='text' width='100%'/>
            </Box>
            <Box display={'flex'}>
            <FormInput onChange={this.handleChange} errorLabel={businessTypeErrorLabel} name='bussiness_type' value={formData.bussiness_type ?? ''} label={this.handleEmpty(formContentData[`${keyType}label_name6` as FormContentKeys] ,translate.BUSINESS_TYPE[lang])} toBeComponent='Chip' items={businessItems} width='100%'/>
            </Box>
            <Box display={'flex'}>
            <FormInput onChange={this.handleChange} errorLabel={intServiceErrorLabel} name='intrested_service' value={formData.intrested_service ?? ''} label={this.handleEmpty(formContentData[`${keyType}label_name7` as FormContentKeys] , translate.INTEREST_SERVICE[lang])} toBeComponent='Chip' items={interestItems} width='100%'/>
            </Box>
            <Box display={'flex'}>
            <FormInput onChange={this.handleChange} errorLabel={feedbackErrorLabel} name='description' value={formData.description} label={this.handleEmpty(formContentData[`${keyType}label_name8` as FormContentKeys] , translate.FEEDBACK[lang])} toBeComponent='Input' type='text' multiline={true} classes={{inputMultiline: 'input-multiline'}} placeholder = {translate.FEEDBACK_PLACEHOLDER[lang]} width='100%' rows={3}/>
            </Box>
        </Grid> 
    )
  }
  // Customizable Area End

  render() {
    const {formType, handleClose} = this.props
    const {formContent: {loading}} = this.state
    const lang = localStorage.getItem('lang') ?? 'en'
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
            <Dialog open={!!formType} fullWidth classes={{
                paper: 'custom-form-paper',
                container: 'custom-form-container'
            }} onClose={() => handleClose(null)}>
            <Grid container direction='row' wrap='nowrap' className='form-container'>
                <Grid item className='left-container'>
                    <Box className='left-bg' />
                    <Box className = "semi-circle1" />
                    <Box className={'semi-circle2'}>
                        <img src={semiCircleIcon} alt='semi-circle' />
                    </Box>
                    <Box className="shadow-bottom" />
                    <Box className='left-text'>
                        <img src={idea} alt= 'idea' style={{marginBottom: "1rem"}} />
                        <p style={webStyle.typoWidth}>{translate.IMAGE_TEXT[lang]}</p>
                    </Box>
                </Grid>
                <ShowComponent show={loading}>
                  <Loader loading/>
                </ShowComponent>
                <ShowComponent show={!loading}>
                <Grid item container direction='column' wrap='nowrap'className='right-container'>
                   {formType && <Box>
                        <Typography variant='subtitle2'>{title[formType](lang)}</Typography>
                    </Box>
                    }
                    <Box>
                        <Typography variant='overline'>{translate.FILL_FORM[lang]}</Typography>
                    </Box>
                    {
                        (formType === 'Book'  || formType === 'Holiday' || formType === 'Room') &&  (
                           this.template1()
                        )
                    }
                    {
                        (formType === 'Franchise' || formType === 'Development' ) && (
                            this.template2()
                        )
                    }
                    <Box paddingBottom={'1.5rem'}>
                    <Button classes={{
                                root: 'custom-form-submit-button'
                            }} 
                            onClick={this.handleValidationAndSubmit}
                            data-testid="submit-form">
                                {translate.SUBMIT[lang]}
                    </Button>
                    </Box>
                  </Grid>
                </ShowComponent>
            </Grid>
        </Dialog>
        </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key:string]: {
    [key:string]: string | number 
  }
} = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  typoWidth: {
    maxWidth: '22.625rem',
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2.25rem',
    letterSpacing: '0',
    textTransform: 'none'
  }
};
// Customizable Area End
