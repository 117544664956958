// Customizable Area Start
export const image_hotel = require("../assets/image_hotel.png");
export const image_dummy = require("../assets/image_dummy.png");
export const image_dummy_2 = require("../assets/image_dummy_2.png");
export const air_condition = require("../assets/air-conditioner 1.svg");
export const bath = require("../assets/bath 1.svg");
export const blanket = require("../assets/blanket 1.svg");
export const cocktails = require("../assets/cocktails 1.svg");
export const kettle = require("../assets/kettle 1.svg");
export const tv = require("../assets/tv 1.svg");
export const vault = require("../assets/vault 1.svg");
export const image_dummy_3 = require("../assets/Rectangle 215.png");
export const image_dummy_4 = require("../assets/Rectangle 230.png");
export const image_dummy_5 = require("../assets/Rectangle 242.png");
export const image_dummy_6 = require("../assets/Rectangle 243.png");
export const image_dummy_7 = require("../assets/Rectangle 261.png");
export const _4stars = require("../assets/4-stars 3.svg");
export const coffee = require("../assets/coffee 3.svg");
export const conference = require("../assets/conference 3.svg");
export const dinner = require("../assets/dinner 3.svg");
export const dumbbell = require("../assets/dumbbell 3.svg");
export const key = require("../assets/key 3.svg");
export const kitchen = require("../assets/kitchen 3.svg");
export const swimming_pool = require("../assets/swimming-pool 3.svg");
export const woman = require("../assets/woman 3.svg");
export const hero_image1 = require("../assets/Rectangle 376.png");
export const hero_image2 = require("../assets/Rectangle 380.png");
export const hero_image3 = require("../assets/Rectangle 382.png");
export const hero_image4 = require("../assets/Rectangle 384.png");
export const hero_image5 = require("../assets/Rectangle 385.png");
export const hero_image6 = require("../assets/Rectangle 386.png");
export const hero_image7 = require("../assets/Rectangle 376(1).png");
export const hero_image8 = require("../assets/Rectangle 380(1).png");
export const hero_image9 = require("../assets/Rectangle 382(1).png");
export const new_project_1 = require("../assets/New Project 1.svg");
export const catalogue_image1 = require("../assets/Rectangle 398.png");
export const catalogue_image2 = require("../assets/Rectangle 399.png");
export const catalogue_image3 = require("../assets/Rectangle 400.png");
export const catalogue_image4 = require("../assets/Rectangle 401.png");
export const star = require("../assets/star 6.png");

export const profileicon = require('../assets/profileIcone.png')
export const emailIcon = require('../assets/emailIcone.png')
export const Ourhotel1 = require('../assets/Ourhotel1.png')
export const Ourhotel2 = require('../assets/Ourhotel2.png')
export const Ourhotel3 = require('../assets/Ourhotel3.png')
export const Ourhotelfirst = require('../assets/ourhotelfirst.png')
export const telephone = require('../assets/group_telephone.svg')
// Customizable Area End
